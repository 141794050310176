import { isPlatform } from '@ionic/react';

/* eslint-disable no-underscore-dangle */
const isLocal = typeof window._env_ === 'undefined';

// check if runtime variables are defined, if not, use process var
const context = isLocal ? process.env : window._env_;

type Environment = 'DEV' | 'TEST' | 'PROD';

export const isEnvironment = (env: Environment[] | Environment) => {
  const currentEnv =
    isLocal && !isPlatform('capacitor') ? 'DEV' : getGraphQlEnv();
  if (Array.isArray(env)) {
    return env.includes(currentEnv);
  }

  return env === currentEnv;
};

// For now, checking the graphql server to determine env
function getGraphQlEnv() {
  const graphServer = context.REACT_APP_GRAPH_SERVER;
  if (graphServer.match(/\.dev\./)) {
    return 'DEV';
  }
  if (graphServer.match(/\.test\./)) {
    return 'TEST';
  }
  return 'PROD';
}

export function getEnvironment() {
  if (isLocal && !isPlatform('capacitor')) return 'LOCAL';

  return getGraphQlEnv();
}

// A great way of debugging environment related configurations
// console.info("Configuration:", {
//   ...context,
//   ENV: getGraphQlEnv()
// });
export default {
  ...context,
  ENV: getGraphQlEnv(),
  isEnvironment,
};
