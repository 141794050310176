import { Client, Conversation, Message } from '@twilio/conversations';
import dayjs, { Dayjs } from 'dayjs';
import { createContext, Dispatch, SetStateAction } from 'react';
import { Channel, UnreadConversations } from '../types';
import { SelectOption } from '../components/message/types';

type ChannelState = {
  publicChannels: Channel[];
  privateChannels: Channel[];
};

type Context = {
  employees: SelectOption[];
  client: Client | null;
  generalChannelId?: string;
  chatEmployeeIds: (string | undefined)[];
  unreadConversations: {
    channels: {
      [key: string]: number;
    };
    total: number;
  } | null;

  mapPublicConversation: (conversation: Conversation) => Promise<{
    key: string;
    label: string | null;
    unreadMessageCount: number;
  }>;
  mapPrivateConversation: (
    conversation: Conversation,
    employees?: SelectOption[],
    userId?: string,
  ) => Promise<{
    key: string;
    recipientIds: string[] | undefined;
    label: string | null;
    unreadMessageCount: number;
    lastMessage: {
      content: string | null;
      timestamp: Dayjs;
    };
  }>;
  channels: ChannelState;
  setChannels: Dispatch<SetStateAction<ChannelState>>;
  getChannelById: (sid: string) => Promise<Conversation | undefined>;
  updateUnreadConversations(
    message: Message,
    setUnreadConversations: Dispatch<SetStateAction<UnreadConversations>>,
    userId?: string,
  ): Promise<void>;
  setUnreadConversations: Dispatch<SetStateAction<UnreadConversations>>;
};

export const ChatContext = createContext<Context>({
  employees: [],
  chatEmployeeIds: [],
  client: null,
  unreadConversations: null,
  setUnreadConversations: () => {},
  channels: {
    privateChannels: [],
    publicChannels: [],
  },
  generalChannelId: '',
  setChannels: () => {},
  getChannelById: () => Promise.resolve(undefined),
  mapPrivateConversation: () =>
    Promise.resolve({
      key: '',
      label: '',
      lastMessage: {
        content: '',
        timestamp: dayjs(),
      },
      recipientIds: [],
      unreadMessageCount: 0,
    }),
  mapPublicConversation: () =>
    Promise.resolve({
      key: '',
      label: '',
      unreadMessageCount: 0,
    }),
  updateUnreadConversations: () => Promise.resolve(),
});
