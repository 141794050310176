import React from 'react';
import {
  ConfigProvider,
  Flex,
  HeaderActions,
  Icon,
  SPACING,
  Typography,
} from '@optii/ui-library';
import { useTranslation } from 'react-i18next';
import { JobPriority } from '@optii/jobs/api/jobs';
import { PRIORITY_ICONS } from '@optii/shared';
import { ReactComponent as RepeatingJobIcon } from '@optii/shared/images/svg/ico-project-job.svg';
import { ReactComponent as ProjectJobIcon } from '@optii/shared/images/svg/ico-repeating-job.svg';

type Props = {
  mode?: 'add' | 'edit';
  jobType: 'template' | 'job';
  priority?: JobPriority;
  onClose: () => void;
};

export function Title({ mode = 'add', jobType, priority, onClose }: Props) {
  const { t } = useTranslation(['common', 'jobs']);

  const PRIORITY = PRIORITY_ICONS[priority as keyof typeof PRIORITY_ICONS];

  const renderTitle = () => {
    if (mode === 'edit' && jobType === 'template') {
      return (
        <>
          <RepeatingJobIcon />
          <Typography.Title level={3}>Locations</Typography.Title>
        </>
      );
    }

    if (mode === 'edit') {
      return (
        <>
          <ProjectJobIcon />
          <Typography.Title level={3}>{t('jobs:Edit Job')} #1</Typography.Title>
        </>
      );
    }
    return <Typography.Title level={3}>{t('jobs:Add Job')}</Typography.Title>;
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Typography: {
            fontFamily: 'Montserrat',
            titleMarginTop: SPACING.NONE,
          },
        },
      }}
    >
      <Flex align="center" justify="space-between" gap={SPACING.SIZE_MD}>
        {mode === 'edit' ? <Icon component={PRIORITY} /> : null}
        {renderTitle()}
        <HeaderActions onClose={onClose} responsive />
      </Flex>
    </ConfigProvider>
  );
}
