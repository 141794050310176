import { useCallback, useContext } from 'react';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { UPDATE_JOB, Session, GA_EVENTS } from '@optii/shared';
import GoogleAnalyticsClient from '@optii/shared/utils/GoogleAnalyticsClient';

import {
  JOB_ADVANCED_STATUSES,
  JOB_STATUSES,
} from '../JobForm/JobForm.constants';
import { THandleAddNote, TUseJobNotes } from './JobNotes.types';

export function useJobNotes(props: TUseJobNotes) {
  const { jobRefetch, job, setActiveTab, shard } = props;

  const { globalSnack } = useContext(Session);

  const { t } = useTranslation(['assets', 'common', 'jobs'], {
    useSuspense: false,
  });

  const [updateJob, { loading: updateJobLoading }] = useMutation(UPDATE_JOB);

  const handleAddNote = useCallback(
    async (_values: THandleAddNote) => {
      const seletedStatus =
        job?.status === JOB_STATUSES.notStarted.value
          ? JOB_ADVANCED_STATUSES.new?.value
          : job?.status;

      try {
        await updateJob({
          variables: {
            id: job.id,
            input: {
              action: job?.action,
              status: seletedStatus,
              notes: [
                {
                  text: _values.note,
                },
              ],
            },
          },
          context: {
            _shard: shard,
          },
        });

        _values?.setNote('');

        if (typeof jobRefetch === 'function') {
          setActiveTab('2');
          jobRefetch();
        }

        GoogleAnalyticsClient.event(GA_EVENTS.addNoteJob);
      } catch (err) {
        console.error("Couldn't add note!", err);

        globalSnack({
          message: t('jobs:There was a problem adding your note'),
          timeout: 5000,
          error: true,
        });
      }
    },
    [globalSnack, job, jobRefetch, setActiveTab, t, updateJob, shard],
  );

  return {
    jobRefetch,
    updateJobLoading,
    handleAddNote,
  };
}
