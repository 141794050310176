import React from 'react';

import { Spin as AntSpin, ConfigProvider, SpinProps, ThemeConfig } from 'antd';

type Props = SpinProps & {
  theme?: ThemeConfig;
};

export function Spin(props: Props) {
  const { theme, ...rest } = props;
  return (
    <ConfigProvider theme={theme}>
      <AntSpin {...rest} />
    </ConfigProvider>
  );
}
