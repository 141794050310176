import {
  Dropdown as AntDropdown,
  ConfigProvider,
  DropdownProps,
  ThemeConfig,
} from 'antd';

type Props = DropdownProps & {
  theme?: ThemeConfig;
};

export function Dropdown(props: Props) {
  const { children, theme, ...rest } = props;

  return (
    <ConfigProvider theme={theme}>
      <AntDropdown {...rest}>{children}</AntDropdown>
    </ConfigProvider>
  );
}
