import React from 'react';

export type TJobDetailsContextJob = {
  id?: string | number;
  type?: string;
  action?: string;
  item?: string;
  items?: any[];
  locations?: any;
  asset?: any;
  status?: string;
  priority?: string;
  doBy?: number;
  department?: any;
  role?: any;
  assignee?: any;
  parentJobId?: number | null;
  attachments?: string[];
  parentChecklistTaskId?: string | number;
  metadata?: any;
  checklists?: any[];
  creditValue?: string;
  durationMin?: string;
  timeWindowStart?: number;
  timeWindowEnd?: number;
  isRushed?: boolean;
};

export type TJobDetailsContext = {
  shard?: string;
  job: TJobDetailsContextJob;
  setJob: (a: any) => void;
  setAsset: (a: any) => void;
  jobRefetch: (a: any) => void;
  loading: boolean;
  audit: any;
  close: (a: any) => void;
  updateJob: any;
  updateJobMetadata: any;
  updateCheklistTask: (a: any) => void;
  submitted: boolean;
  toggleSubmitted: (a: any) => void;
  isUpdateChecklistTaskLoading: boolean;
  toggleIsUpdateChecklistTaskLoading: (a: any) => void;
  asset: any;
  refetch: () => void;
  jobItems: any;
  employees: any;
  departments: any;
  roles: any;
  assets: any;
  activeLocations: any;
  activeLocationsAssetType: any;
  loadLocationListOptions: (a: any) => void;
  loadLocationAssetTypeListOptions: (a: any) => void;
  getAssetTypesByJobItem: (a: any) => void;
  jobItemHasAssociatedAssets: boolean;
  assetsLoading: boolean;
};

export const JobDetailsContext = React.createContext<TJobDetailsContext>({
  shard: '',
  job: {},
  setJob: () => {},
  setAsset: () => {},
  jobRefetch: () => {},
  loading: false,
  audit: [],
  close: (a: any) => {},
  updateJob: () => {},
  updateJobMetadata: () => {},
  updateCheklistTask: () => {},
  submitted: false,
  toggleSubmitted: () => {},
  isUpdateChecklistTaskLoading: false,
  toggleIsUpdateChecklistTaskLoading: () => {},
  asset: {},
  refetch: () => {},
  jobItems: [],
  employees: [],
  departments: [],
  roles: [],
  assets: [],
  activeLocations: [],
  activeLocationsAssetType: [],
  loadLocationListOptions: (a: any) => {},
  loadLocationAssetTypeListOptions: (a: any) => {},
  getAssetTypesByJobItem: (a: any) => {},
  jobItemHasAssociatedAssets: false,
  assetsLoading: true,
});
