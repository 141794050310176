import { SelectOption } from '@optii/global';
import { Dayjs } from 'dayjs';
import { createContext } from 'react';

export type CustomJobItemOption = SelectOption & {
  isAssociatedWithAnAsset: boolean;
};

export type CustomDepartmentOption = SelectOption & {
  departmentCode: string;
};

export type CustomRoleOption = SelectOption & {
  description?: string | null;
};

type CustomAssigneeOption = SelectOption & {
  userName: string;
  firstName: string;
  lastName: string;
};

export type DataPayload = {
  options: SelectOption[];
  loading: boolean;
};

export type LocationWithAsset = {
  value: string;
  label: string;
  notes: string;
  assetTypeDisplayName: string;
  item: string;
  location: {
    id: string;
    shortDisplayName: string;
  };
};

export type FormContextProps = {
  shard?: string;
  suggestion?: Dayjs;
  suggestionLoading: boolean;
  items: {
    options: CustomJobItemOption[];
    loading: boolean;
  };
  departments: {
    options: CustomDepartmentOption[];
    loading: boolean;
  };
  roles: {
    options: CustomRoleOption[];
    loading: boolean;
  };
  assignees: {
    options: CustomAssigneeOption[];
    loading: boolean;
  };
  checklists: DataPayload;
  assets: {
    options: LocationWithAsset[];
    loading: false;
  };
};

export const FormContext = createContext<FormContextProps>({
  shard: '',
  suggestion: undefined,
  suggestionLoading: false,
  assets: {
    options: [],
    loading: false,
  },
  items: {
    options: [],
    loading: false,
  },
  departments: {
    options: [],
    loading: false,
  },
  roles: {
    options: [],
    loading: false,
  },
  assignees: {
    options: [],
    loading: false,
  },
  checklists: {
    options: [],
    loading: false,
  },
});
