import { useTranslation } from 'react-i18next';
import {
  Flex,
  Grid,
  Row,
  Typography,
  ConfigProvider,
  Col,
  SPACING,
  Spin,
} from '@optii/ui-library';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { CSSProperties, useContext, useMemo } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Session } from '@optii/shared';
import { Channels } from './components/channels';
import { Messages } from './components/message';
import { ChatContext } from './context/chat.context';
import { useListEmployeesQuery } from './api/data';
import useChat from './hooks/useChat';
import { useChatEmployeesIdQuery } from './api/chatEmployees';

const TITLE_STYLE: CSSProperties = {
  marginTop: SPACING.NONE,
  fontFamily: 'Montserrat',
  fontWeight: 400,
  marginBottom: SPACING.SIZE_MD,
};

export function Chat() {
  const {
    client,
    generalChannelId,
    unreadConversations,
    updateUnreadConversations,
    setUnreadConversations,
  } = useContext(Session);

  const { useBreakpoint } = Grid;
  const breakpoints = useBreakpoint();
  const match = useRouteMatch<{ id: string }>('/messages/:id');
  const { t } = useTranslation(['chat', 'common']);
  const { data: employeesData, loading: employeesLoading } =
    useListEmployeesQuery({
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
    });

  const { data: chatEmployeesData } = useChatEmployeesIdQuery();

  const chatEmployeeIds = useMemo(
    () => chatEmployeesData?.chatEmployeesId?.map((id) => id?.toString()) || [],
    [chatEmployeesData?.chatEmployeesId],
  );

  const employees = useMemo(
    () =>
      employeesData?.page?.edges?.map(({ node }) => {
        const label = `${node.userFirstName} ${node.userLastName}`;
        return {
          label,
          value: String(node.userId),
          id: node.userId,
          status: node.status,
        };
      }) || [],
    [employeesData],
  );

  const {
    channels,
    getChannelById,
    mapPrivateConversation,
    mapPublicConversation,
    setChannels,
  } = useChat(client, employees);

  const contextValue = useMemo(
    () => ({
      client,
      employees,
      unreadConversations,
      channels,
      getChannelById,
      mapPrivateConversation,
      setChannels,
      updateUnreadConversations,
      setUnreadConversations,
      chatEmployeeIds,
      generalChannelId,
      mapPublicConversation,
    }),
    [
      client,
      employees,
      unreadConversations,
      channels,
      getChannelById,
      mapPrivateConversation,
      mapPublicConversation,
      setChannels,
      updateUnreadConversations,
      setUnreadConversations,
      chatEmployeeIds,
      generalChannelId,
    ],
  );

  const calculateTitlePush = () => {
    if (breakpoints.xxl) return 4;

    if (breakpoints.sm) return 6;

    return 6;
  };

  return !client && employeesLoading ? (
    <Flex
      align="center"
      justify="center"
      style={{
        height: '50vh',
      }}
    >
      <Spin size="large" indicator={<LoadingOutlined spin />} />
    </Flex>
  ) : (
    <ChatContext.Provider value={contextValue}>
      <ConfigProvider>
        {!breakpoints.xs ? (
          <>
            {!match?.params.id ? (
              <Redirect to={`/messages/${generalChannelId}`} />
            ) : null}

            <Row>
              <Col push={calculateTitlePush()}>
                <Typography.Title level={3} style={TITLE_STYLE}>
                  {t('common:Chat')}
                </Typography.Title>
              </Col>
            </Row>
          </>
        ) : null}
        <Switch>
          <Route exact component={Channels} path="/messages" />
          <Route component={Messages} path="/messages/:id" />
        </Switch>
      </ConfigProvider>
    </ChatContext.Provider>
  );
}
export default Chat;
