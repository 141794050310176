import dayjs from 'dayjs';
import moment, { Moment } from 'moment';
import _ from 'lodash';

import type {
  Checklist,
  GetJobByIdQuery,
  Job,
  JobStatus,
  Location,
  Maybe,
} from '@optii/jobs/api/jobs';
import type {
  Asset,
  RepeatingJobQuery,
  RoomSummary,
} from '@optii/jobs/api/repeatingJobs';
import { CHECKLIST_TASK_TYPE_CONFIG } from '@optii/jobs/checklists/constants';

import {
  JOB_ADVANCED_STATUSES,
  JOB_STATUSES,
} from '../JobForm/JobForm.constants';
import { JOB_CARD_ACTION } from './JobStatusCard.constants';
import type {
  TCompletableChecklistTasks,
  TGetJobStatusCardAction,
  TGetJobStatusCardStatus,
  TGetJobSource,
  TJobCardAttachmentsData,
  TJobCardData,
  TJobCardDataAssignmentAndRoomType,
  TJobCardDataJob,
  TJobCardDataStatus,
  TJobCardDataTimeType,
  TJobSources,
  TGetJobStatusCardCleaningStatus,
  TGetJobStatusCardReservationsIcons,
  TJobCardReservations,
} from './JobStatusCard.types';
import {
  TJobAdvancedStatusesKeys,
  TJobStatusesKeys,
  TJobStatusesValues,
} from '../JobForm/JobForm.types';

export function getJobStatusCardAction({ action, t }: TGetJobStatusCardAction) {
  const actionLabel =
    JOB_CARD_ACTION[action as keyof typeof JOB_CARD_ACTION].label;
  const translatedActionLabel = action ? t(`jobs:${actionLabel}`) : '';

  return translatedActionLabel;
}

export function getJobStatusCardStatus({
  status,
  t,
}: TGetJobStatusCardStatus): {
  value: JobStatus | 'queued';
  key: TJobAdvancedStatusesKeys | TJobStatusesKeys;
  label: string;
} | null {
  const jobStatusesValues = Object.values(JOB_STATUSES);
  const jobStatusesKeys = Object.keys(JOB_STATUSES);

  const currentJobStatusIndex = jobStatusesValues.findIndex(
    (jobStatus) => jobStatus.value === status,
  );
  const currentJobStatus = jobStatusesKeys[currentJobStatusIndex];

  const currentJobStatusLabel =
    JOB_STATUSES[currentJobStatus as keyof typeof JOB_STATUSES]?.label;

  if (currentJobStatusLabel) {
    return {
      value: status,
      key: currentJobStatus as TJobStatusesKeys,
      label: t(`jobs:${currentJobStatusLabel}`),
    };
  }

  const jobAdvancedStatusesValues = Object.values(JOB_ADVANCED_STATUSES);
  const jobAdvancedStatusesKeys = Object.keys(JOB_ADVANCED_STATUSES);

  const currentJobAdvancedStatusIndex = jobAdvancedStatusesValues.findIndex(
    (jobStatus) => jobStatus.value === status,
  );
  const currentJobAdvancedStatus =
    jobAdvancedStatusesKeys[currentJobAdvancedStatusIndex];

  const currentJobAdvancedStatusSameAs =
    JOB_ADVANCED_STATUSES[
      currentJobAdvancedStatus as keyof typeof JOB_ADVANCED_STATUSES
    ]?.sameAs;

  const queuedStatusLabel =
    status === 'queued' ? JOB_ADVANCED_STATUSES.queued?.label : null;

  const statusLabel =
    JOB_STATUSES[currentJobAdvancedStatusSameAs as keyof typeof JOB_STATUSES]
      ?.label || queuedStatusLabel;

  return {
    value: status,
    key: currentJobAdvancedStatusSameAs as TJobAdvancedStatusesKeys,
    label: t(`jobs:${statusLabel}`),
  };
}

export function getJobStatusCardReservationsStatuses({
  roomsData,
}: TGetJobStatusCardReservationsIcons) {
  const rooms = roomsData?.reduce(
    (previous: Array<TJobCardReservations>, current) => {
      const hasOccupancyOrCleaningStatuses =
        current.occupancyStatus && current.cleaningStatus;
      const hasReservations = current.reservations?.length;

      if (hasOccupancyOrCleaningStatuses && hasReservations) {
        return current?.reservations?.reduce(
          (
            previousReservations: Array<TJobCardReservations>,
            currentReservation,
          ) => {
            const hasStatusLabel = !!currentReservation?.statusLabel;

            if (hasStatusLabel) {
              const { statusLabel: currentReservationsStatusLabel } =
                currentReservation;

              const reservationsStatuses = {
                Arrival: 'arrival',
                Departure: 'departure',
                'In-House': 'inHouse',
              };

              return [
                ...previousReservations,
                {
                  status: currentReservationsStatusLabel,
                  icon: reservationsStatuses[
                    currentReservationsStatusLabel as keyof typeof currentReservationsStatusLabel
                  ],
                  vip: !!currentReservation.preferences?.filter(
                    (preference) => preference?.type === 'VipCode',
                  ).length,
                },
              ] as Array<TJobCardReservations>;
            }
            return previousReservations;
          },
          [],
        ) as Array<TJobCardReservations>;
      }

      return previous as Array<TJobCardReservations>;
    },
    [],
  );

  const sortedRooms = _.orderBy(rooms, ['icon'], 'asc');

  return sortedRooms;
}

export function getJobStatusCardReservationsIcons({
  roomsData,
}: TGetJobStatusCardReservationsIcons) {
  const rooms = getJobStatusCardReservationsStatuses({ roomsData });
  const reservations = rooms?.reduce(
    (previous: string[], current: TJobCardReservations) => {
      const vip = current.vip ? 'Vip' : '';
      const hasArrival = previous.find(
        (previousItem) => previousItem === 'arrival',
      );
      const hasArrivalVip = previous.find(
        (previousItem) => previousItem === 'arrivalVip',
      );
      const hasDeparture = previous.find(
        (previousItem) => previousItem === 'departure',
      );
      const hasDepartureVip = previous.find(
        (previousItem) => previousItem === 'departureVip',
      );
      const hasArrivalDeparture = previous.find(
        (previousItem) => previousItem === 'arrivalDeparture',
      );
      const hasArrivalDepartureVip = previous.find(
        (previousItem) => previousItem === 'arrivalDepartureVip',
      );
      const hasInHouse = previous.find(
        (previousItem) => previousItem === 'inHouse',
      );
      const hasInHouseVip = previous.find(
        (previousItem) => previousItem === 'inHouseVip',
      );
      const isArrivalOrDeparture =
        current.icon === 'arrival' || current.icon === 'departure';
      const isInHouse = current.icon === 'inHouse';

      if (
        (isInHouse && hasInHouseVip) ||
        (isArrivalOrDeparture &&
          (hasArrivalDepartureVip || hasArrivalDeparture))
      ) {
        return previous;
      }

      if (isInHouse && hasInHouse && !current.vip) {
        return [
          ...new Set([
            ...previous.filter((previousItem) => previousItem !== 'inHouse'),
            'inHouse',
          ]),
        ];
      }

      if (isInHouse && hasInHouse && current.vip) {
        return [
          ...new Set([
            ...previous.filter((previousItem) => previousItem !== 'inHouse'),
            'inHouseVip',
          ]),
        ];
      }

      if (
        isArrivalOrDeparture &&
        (hasArrival || hasDeparture) &&
        !current.vip
      ) {
        return [
          ...new Set([
            ...previous.filter(
              (previousItem) =>
                previousItem !== 'arrival' && previousItem !== 'departure',
            ),
            'arrivalDeparture',
          ]),
        ];
      }

      if (
        isArrivalOrDeparture &&
        (hasArrivalVip || hasDepartureVip) &&
        current.vip
      ) {
        return [
          ...new Set([
            ...previous.filter(
              (previousItem) =>
                previousItem !== 'arrival' &&
                previousItem !== 'departure' &&
                previousItem !== 'arrivalDeparture' &&
                previousItem !== 'arrivalVip' &&
                previousItem !== 'departureVip',
            ),
            'arrivalDepartureVip',
          ]),
        ];
      }

      return [...new Set([...previous, `${current.icon}${vip}`])];
    },
    [],
  );

  return reservations;
}

export function transformCardData(params: {
  job?: Job & {
    asset?: Asset;
    room?: RoomSummary;
  };
  repeatingJob?: RepeatingJobQuery['job'];
  asset?: Asset;
  roomsData?: Array<RoomSummary>;
  locationData?: Maybe<Location>;
  timezone?: string;
  t: (a: string) => string;
}): TJobCardData {
  const { t, locationData, roomsData } = params;

  const isHousekeeping = params.job?.type === 'housekeeping';

  let jobStatusCardData: TJobCardData = {} as TJobCardData;

  const jobData = params.repeatingJob
    ? (params.repeatingJob
        .jobTemplate as RepeatingJobQuery['job']['jobTemplate'])
    : (params.job as GetJobByIdQuery['job']);

  if (jobData && (params.job?.id || params.job?.metadata)) {
    const isHKJobWithClean =
      isHousekeeping &&
      (params.job?.action === 'stayover' || params.job?.action === 'departure')
        ? 'Clean'
        : '';
    const housekeepingJobName = isHKJobWithClean;
    const serviceJobName = !isHousekeeping
      ? jobData.items
          ?.map((jobItem) => {
            const amount = jobItem?.amount > 1 ? `(${jobItem?.amount})` : '';

            return `${jobItem?.name} ${amount}`;
          })
          .join(', ')
      : null;
    const displayName = serviceJobName || housekeepingJobName || '';

    const job: TJobCardDataJob = {
      id: params.job?.id,
      displayName,
      action: jobData.action,
    };

    const statusLabel = getJobStatusCardStatus({
      status: jobData?.status as JobStatus,
      t,
    }) as TJobCardDataStatus;

    const locations = jobData?.locations?.map((locationItem) => ({
      id: locationItem?.id,
      displayName: locationItem?.shortDisplayName,
    }));

    const checklists = jobData?.checklists?.map(
      (checklistItem) => checklistItem?.id,
    );

    const repeatingJobChecklistTasks =
      params.repeatingJob?.jobTemplate?.checklists &&
      params.repeatingJob.jobTemplate?.checklists;
    const completedJobChecklistTasks = (params.job?.checklists &&
      params.job.checklists.length > 0 &&
      params.job?.checklists?.reduce(
        (previous: Checklist, current: Checklist) => {
          const previousChecklistTasks = previous?.checklistTasks || [];
          const currentChecklistTasks = current?.checklistTasks || [];

          return {
            ...previous,
            checklistTasks: [
              ...previousChecklistTasks,
              ...currentChecklistTasks,
            ],
          };
        },
      )) as Checklist;

    const completableChecklistTasksGroup: TCompletableChecklistTasks =
      (repeatingJobChecklistTasks ||
        completedJobChecklistTasks?.checklistTasks?.filter(
          (checklistTask) =>
            CHECKLIST_TASK_TYPE_CONFIG[
              checklistTask?.taskType as keyof typeof CHECKLIST_TASK_TYPE_CONFIG
            ]?.fulfillment?.fulfillable,
        )) as TCompletableChecklistTasks;
    const doneChecklistTasks = completableChecklistTasksGroup?.filter(
      (checklistTask) =>
        CHECKLIST_TASK_TYPE_CONFIG[
          checklistTask?.taskType as keyof typeof CHECKLIST_TASK_TYPE_CONFIG
        ]?.fulfillment?.isFulfilled(checklistTask),
    );
    const requiredChecklistTasks = completableChecklistTasksGroup?.filter(
      (checklistTask) => checklistTask.required,
    );
    const requiredDoneChecklistTasks = completableChecklistTasksGroup?.filter(
      (checklistTask) =>
        CHECKLIST_TASK_TYPE_CONFIG[
          checklistTask?.taskType as keyof typeof CHECKLIST_TASK_TYPE_CONFIG
        ]?.fulfillment?.isFulfilled(checklistTask) && checklistTask.required,
    );

    const checklistsSummary = {
      done: doneChecklistTasks?.length,
      total: completableChecklistTasksGroup?.length,
      requiredTotal: requiredChecklistTasks?.length,
      requiredDone: requiredDoneChecklistTasks?.length,
    };

    const timeType: TJobCardDataTimeType = [
      JOB_STATUSES.completed.value,
      JOB_STATUSES.cancelled.value,
    ].includes(statusLabel?.value as TJobStatusesValues)
      ? 'done'
      : 'due';

    let currentTime;
    let isOverdue = false;

    if (params.job && params.job.timeWindowStart) {
      currentTime = dayjs.unix(params.job.timeWindowStart).tz(params.timezone);
    } else if (params.job && params.job.doBy) {
      const dayjsValue =
        typeof params.job.completedAt === 'string'
          ? dayjs(params.job.completedAt)
          : dayjs.unix(params.job.completedAt);
      currentTime =
        timeType === 'done'
          ? dayjsValue.tz(params.timezone)
          : dayjs.unix(params.job.doBy).tz(params.timezone);

      isOverdue = !!(
        timeType === 'due' &&
        dayjs.unix(params.job.doBy).tz(params.timezone) <
          dayjs().tz(params.timezone)
      );
    } else if (params.repeatingJob && params.repeatingJob.endDate) {
      currentTime = dayjs(params.repeatingJob.endDate).tz(params.timezone);
    }

    const scheduleStartTimeAt =
      params.job.scheduleStartTimeAt &&
      dayjs.unix(params.job.scheduleStartTimeAt).tz(params.timezone);

    const timeWindowStart = params.job?.timeWindowStart
      ? dayjs.unix(params.job?.timeWindowStart)
      : null;

    const timeWindowEnd = params.job?.timeWindowEnd
      ? dayjs.unix(params.job?.timeWindowEnd)
      : null;

    const time = {
      day: currentTime?.format('D'),
      month: currentTime?.format('ll'),
      type: timeType,
      credits: params.job?.creditValue,
      timestamp: currentTime?.format('LT'),
      timeWindowStart: timeWindowStart?.format('LT'),
      timeWindowEnd: timeWindowEnd?.format('LT'),
      isOverdue,
    };

    const startTime = {
      day: scheduleStartTimeAt?.format('D'),
      month: scheduleStartTimeAt?.format('ll'),
      timestamp: scheduleStartTimeAt?.format('LT'),
    };

    const roomType =
      (jobData?.locations &&
        jobData?.locations.length === 1 &&
        jobData?.locations[0] &&
        jobData?.locations[0].type) ||
      null;
    const roomTypeCode =
      params?.job?.room?.roomType?.code || locationData?.roomType?.code;

    const hasDepartment = !!jobData?.department?.departmentCode;
    const hasAssignee = !!(
      jobData?.assignee?.id && jobData?.assignee?.firstName
    );

    const assignment: TJobCardDataAssignmentAndRoomType = {
      department: hasDepartment
        ? {
            code: jobData?.department?.departmentCode || '',
            displayName: jobData?.department?.displayName || '',
          }
        : undefined,
      assignee: hasAssignee
        ? {
            id: jobData?.assignee?.id || '',
            displayName: `${jobData?.assignee?.firstName} ${jobData?.assignee?.lastName?.substring(0, 1)}`,
          }
        : {
            id: '',
            displayName: t('jobs:Unassigned'),
          },
      roomType,
      roomTypeCode,
    };

    const [source] = jobData?.metadata?.source?.split('-') || '';
    const priority = jobData?.priority;
    const attachments = jobData?.attachments;
    const rush = params.job?.isRushed;
    let notes = '';
    if (Array.isArray(jobData.notes)) {
      const sortedArray = [...jobData.notes] as {
        time: number;
        text: string;
      }[];

      notes = sortedArray.sort((a, b) => b.time - a.time)[0].text;
    }

    const isEscalated = !!jobData?.isEscalated;

    jobStatusCardData = {
      job,
      status: jobData?.status as JobStatus,
      statusLabel: statusLabel.value as string,
      locations,
      roomsData,
      notes,
      checklists,
      checklistsSummary,
      asset: params.asset || params.job.asset,
      room: params.roomsData || params.job.room,
      time,
      startTime,
      assignment,
      rush,
      priority,
      source,
      attachments,
      isEscalated,
    };
  }

  return jobStatusCardData;
}

export function transformAttachmentsData(
  data: Omit<GetJobByIdQuery, 'audit'>,
): TJobCardAttachmentsData | null {
  if (data?.job?.attachments) {
    return data?.job?.attachments as TJobCardAttachmentsData;
  }

  return null;
}

export function getJobSource({
  isProject,
  isRepeating,
  isHousekeeping,
  isService,
}: TGetJobSource): TJobSources {
  let source: TJobSources = isHousekeeping ? 'housekeeping' : 'service';

  if (isService && isProject) {
    source = 'project';
  }

  if (isService && isRepeating) {
    source = 'repeating';
  }

  return source;
}

export function getRoomStatus(props: {
  room: RoomSummary;
  generateInPropertyTime: (a: string | ReturnType<typeof moment>) => Moment;
}) {
  const { room, generateInPropertyTime } = props;

  const { cleaningStatus, occupancyStatus, outOfOrderDates } = room;
  const todayDate = generateInPropertyTime(moment());
  let displayCode = '';
  if (occupancyStatus) {
    const occupancyCode = occupancyStatus[0];
    displayCode = `${occupancyCode || ''}${
      cleaningStatus ? cleaningStatus[0] : ''
    }`;
  } else if (outOfOrderDates) {
    const outOfOrderFlag = outOfOrderDates.find((date) => {
      if (date?.startDate && date?.endDate) {
        return (
          todayDate.isSameOrAfter(generateInPropertyTime(date.startDate)) &&
          todayDate.isSameOrBefore(generateInPropertyTime(date.endDate))
        );
      }

      if (date?.startDate)
        return todayDate.isSameOrAfter(generateInPropertyTime(date.startDate));

      if (date?.endDate)
        return todayDate.isSameOrBefore(generateInPropertyTime(date.endDate));

      return false;
    });
    if (outOfOrderFlag) {
      displayCode = 'OOO';
    }
  }

  return displayCode;
}

export function getRoomReservationVIPStatus( props: {
  room: RoomSummary;
}) {
  const { room } = props;
  if (room) {
    const { reservations } = room;
    if (reservations?.length && reservations[0]?.preferences) {
      const { preferences } = reservations[0];
      const vipStatus = preferences
      ?.filter((preference) => preference && preference.type === 'VipCode')
      .reduce(
        (accumulator, preference, vipStatusIndex) => 
          accumulator.concat(
            vipStatusIndex === 0 ? preference?.value as string : `, ${preference?.value as string}`,
          ),
        '',
      );
      return vipStatus;
    }
  }
  return '';
}

export function getJobStatusCardCleaningStatus({
  roomsData,
}: TGetJobStatusCardCleaningStatus) {
  const cleaningStatus = roomsData?.reduce((previous: string[], current) => {
    const { cleaningExceptionStatus } = current;
    const cleaningExceptionStatusesKeys =
      (cleaningExceptionStatus && Object.keys(cleaningExceptionStatus)) || [];
    const cleaningExceptionStatuses = cleaningExceptionStatusesKeys?.filter(
      (cleaningExceptionStatusesKey) =>
        cleaningExceptionStatus &&
        cleaningExceptionStatusesKey !== '__typename' &&
        !!cleaningExceptionStatus[
          cleaningExceptionStatusesKey as keyof typeof cleaningExceptionStatus
        ],
    );
    const hasCleaningExceptionStatuses = cleaningExceptionStatuses?.length;

    if (hasCleaningExceptionStatuses) {
      return [...previous, ...cleaningExceptionStatuses];
    }

    return previous;
  }, []);

  return cleaningStatus;
}

export const getCorrectDroppableProps = (provided: any, snapshot: any) => {
  const draggableProps = { ...provided.draggableProps };

  if (snapshot.isDragging) {
    const offset = { x: 0, y: 300 };
    const jobCardPageY = (window as any).jobCardPageY ?? 1;
    const jobCardScroll = (window as any).jobCardScroll ?? 1;

    const minusY =
      jobCardPageY + jobCardScroll > window.innerHeight - offset.y
        ? (jobCardPageY ?? 1) / offset.y
        : offset.y;

    const x = provided.draggableProps.style.left - offset.x;
    const y = provided.draggableProps.style.top - minusY;

    return {
      ...draggableProps,
      style: {
        ...draggableProps.style,
        left: x,
        top: y,
      },
    };
  }
  return provided.draggableProps;
};
