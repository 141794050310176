import type { MenuProps, ThemeConfig } from 'antd';
import { Menu as AntMenu, ConfigProvider } from 'antd';
import React from 'react';

type Props = MenuProps & {
  configTheme?: ThemeConfig;
};

export function Menu(props: Props) {
  const { configTheme, ...rest } = props;
  return (
    <ConfigProvider theme={configTheme}>
      <AntMenu {...rest} />
    </ConfigProvider>
  );
}
