import { SPACING, Flex } from '@optii/ui-library';

import React, { Dispatch, memo, SetStateAction } from 'react';
import { Client } from '@twilio/conversations';
import { SelectOption } from '../../message/types';
import { EmployeeOption } from './EmployeeOption';

type SelectEmployeeProps = {
  setSelectedEmployees: Dispatch<SetStateAction<string[]>>;
  selectedEmployees: string[];
  options: SelectOption[];
  client: Client | null;
  canSelectMultiple: boolean;
};

export const SelectEmployees = memo(
  ({
    setSelectedEmployees,
    selectedEmployees,
    options,
    client,
    canSelectMultiple,
  }: SelectEmployeeProps) => (
    <Flex
      align="center"
      vertical
      gap={SPACING.SIZE_DEFAULT}
      style={{
        maxHeight: 400,
        overflow: 'auto',
        marginBottom: SPACING.SIZE_L,
      }}
    >
      {options.map(({ label, value }) => (
        <EmployeeOption
          client={client}
          label={label}
          data-testid="search-option"
          isSelected={selectedEmployees.some(
            (employeeId) => value === employeeId,
          )}
          value={value}
          key={value}
          onClick={() =>
            setSelectedEmployees((prev) => {
              if (prev.some((prevEmployee) => prevEmployee === value)) {
                return prev.filter((prevEmployee) => prevEmployee !== value);
              }
              if (canSelectMultiple) {
                return prev.concat([value]);
              }
              return [value];
            })
          }
        />
      ))}
    </Flex>
  ),
);
