import React, { useState, memo, useContext } from 'react';
import {
  Drawer,
  Tabs,
  Header,
  HeaderActions,
  IconButton,
  ICON_BUTTON_THEME,
  COLORS,
  Button,
} from '@optii-solutions/ui-library';
import { Col, Row, Typography, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';
import { omitDeep } from '@apollo/client/utilities';
import { StringParam, useQueryParams } from 'use-query-params';

import { PropertyDetailsContext } from '@optii/shared';

import { ReactComponent as VirtualIcon } from 'images/svg/virtual-location-icon.svg';
import { initGQLData as formatData } from 'utils/initGQLData';
import {
  GET_LOCATION_BY_ID,
  GET_ROOM_SUMMARY,
  GET_TODAY_JOBS,
  UPDATE_JOB,
  GET_LOCATION_LOGS,
} from 'queries';
import {
  CloseCircleOutlined,
  EditFilled,
  EditOutlined,
} from '@ant-design/icons';
import { zones } from 'utils/constants/timeZones';
import { LOCATION_TYPES_NAMES, PRODUCT_ACCESS } from 'utils/constants';
import useProductAccess from 'utils/useProductAccess';
import LocationSearch from './LocationSearch';
import LocationDetails from './LocationDetails';
import { SIZES, STYLES } from './constants';
import LocationForm from './LocationForm';
import { CurrentJobs } from './CurrentJobs';
import { TAssociatedVirtualLocations, TQueryLocation } from './types';
import {
  CleaningExceptionStatus,
  LocationHousekeepingStatus,
} from './LocationHousekeepingStatus';
import { ReservationDetails } from './ReservationDetails';
import ActivityLog from './ActivityLog';

const HOUSEKEEPING_FEATURE_FLAG = true;
const CurrentJobsFeatureFlag = true;

const RESERVATION_DETAILS_FLAG = true;

interface ILocationViewProps {
  close: () => void;
  show: boolean;
  id: string;
  isMultiVirtual?: boolean;
}

type TProperty = {
  property: {
    regionalSettings: {
      timeZone: string;
    };
  };
};

function LocationView({
  close,
  show,
  id,
  isMultiVirtual = false,
}: ILocationViewProps) {
  const [selectedVirtualLocation, updateSelectedVirtualLocation] =
    useState<TAssociatedVirtualLocations | null>(null);
  const { canProduct } = useProductAccess();
  const [open, setOpen] = useState(show);
  const [showEdit, setShowEdit] = useState(false);
  const { t } = useTranslation(['common', 'floorplan', 'fields']);
  const { property } = useContext(PropertyDetailsContext.Context) as TProperty;
  const [updateJob, { loading: updateLoading }] = useMutation(UPDATE_JOB);

  const [query, setQuery] = useQueryParams({
    openJob: StringParam,
    openRepeatingJob: StringParam,
    shard: StringParam,
  });

  const { data, refetch } = useQuery<TQueryLocation>(GET_LOCATION_BY_ID, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id,
    },
    context: {
      _shard: query.shard,
    },
    onCompleted: () => {
      setOpen(true);
    },
    skip: !id || !show,
  });

  const {
    data: locationLogs,
    refetch: refetchLogs,
    loading: loadingLocationLogs,
  } = useQuery(GET_LOCATION_LOGS, {
    variables: { locationId: id },
    context: { _instance: 'node', _shard: query.shard },
    skip: !id || !show,
  });

  const {
    data: roomSummary,
    refetch: roomSummaryRefetch,
    loading: loadingRoomSummary,
  } = useQuery(GET_ROOM_SUMMARY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id,
    },
    context: { _instance: 'node', _shard: query.shard },
    skip: !id || !show || !canProduct([PRODUCT_ACCESS.pms_Data]),
  });

  const { data: unformattedTodayJobs, refetch: todayJobsRefetch } = useQuery(
    GET_TODAY_JOBS,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        locationId: parseInt(id, 10),
        utc:
          zones.find((z) => z.id === property?.regionalSettings?.timeZone)
            ?.offset || 0,
        first: 10,
      },
      context: { _instance: 'node', _shard: query.shard },
      skip: !id || !show || !CurrentJobsFeatureFlag,
    },
  );

  const todayJobs = formatData(unformattedTodayJobs);

  const cleaningExceptionStatus = omitDeep(
    roomSummary?.roomSummary?.cleaningExceptionStatus,
    '__typename',
  ) as CleaningExceptionStatus;

  if (!data?.GetLocationById) return null;
  const { GetLocationById: selectedLocation } = data;
  const {
    longDisplayName,
    isVirtual,
    locationType: { displayName: locationTypeName },
  } = selectedLocation;

  const items = [
    {
      key: '0', // Key to add a empty space to avoid issues with padding
      label: '',
      disabled: true,
    },
    {
      key: '1',
      label: t('floorplan:Location Details'),
      children: (
        <LocationDetails
          location={selectedLocation}
          onSelectVirtualLocation={(virtualLocation) =>
            updateSelectedVirtualLocation(virtualLocation)
          }
        />
      ),
    },
    {
      key: '2',
      label: t('floorplan:Activity Log'),
      destroyInactiveTabPane: true,
      children: (
        <ActivityLog
          data={locationLogs ? locationLogs.logs : []}
          loading={loadingLocationLogs}
        />
      ),
    },
    /* , {
    key: `location-view-tab-2`,
    label: t("floorplan:Upcoming Jobs"),
    children: 'Content of Tab Pane 2',
    disabled: true,
  }, {
    key: `location-view-tab-3`,
    label: t("floorplan:Job History"),
    children: 'Content of Tab Pane 3',
    disabled: true,
  }, {
    key: `location-view-tab-4`,
    label: t("floorplan:Activity"),
    children: 'Content of Tab Pane 3',
    disabled: true,
  }, {
    key: `location-view-tab-5`,
    label: t("floorplan:Assets"),
    children: 'Content of Tab Pane 3',
    disabled: true,
  } */
  ];

  function onClose() {
    if (query.openJob && onClose && typeof onClose === 'function') {
      const jobId = query.openJob;
      setQuery({
        openJob: '',
      });

      setOpen(false);

      setTimeout(() => {
        close();
        setQuery({
          openJob: jobId,
          openRepeatingJob: undefined,
        });
      }, 500);

      return;
    }

    setOpen(false);
    setTimeout(close, 500);
  }

  function toggleRush(
    rushLocationId: number,
    isRushed: boolean,
    action?: string,
  ) {
    updateJob({
      variables: {
        rushLocationId,
        input: {
          isRushed,
          action,
        },
      },
    }).then(() => todayJobsRefetch());
  }

  return (
    <Drawer
      open={open}
      width={SIZES.large}
      onClose={() => onClose()}
      destroyOnClose
      styles={{
        body: STYLES.drawerBody,
        header: STYLES.drawerHeader,
      }}
      title={
        <Header
          customRender={
            <>
              {!isMultiVirtual && (
                <Row>
                  <LocationSearch
                    onSelectLocation={(location) => {
                      refetch({
                        id: location.id,
                      });
                      todayJobsRefetch({
                        locationId: parseInt(location.id, 10),
                      });
                      refetchLogs({
                        locationId: parseInt(location.id, 10),
                      });
                      roomSummaryRefetch({
                        id: location.id,
                      });
                    }}
                  />
                </Row>
              )}
              <Row
                align="bottom"
                justify="center"
                wrap={false}
                style={STYLES.row}
              >
                <Col md={17} xs={11}>
                  <Typography.Title level={3} style={STYLES.header3}>
                    {isVirtual && (
                      <VirtualIcon title={t('floorplan:Virtual Location')} />
                    )}{' '}
                    {longDisplayName}
                  </Typography.Title>
                </Col>
                <Col md={7} xs={12} style={STYLES.headerActionColumn}>
                  <HeaderActions
                    customRender={
                      <Space>
                        <Button
                          onClick={() => {
                            refetch();
                            todayJobsRefetch();
                            roomSummaryRefetch();
                            refetchLogs();
                          }}
                        >
                          {t('floorplan:Refresh')}
                        </Button>
                        <IconButton
                          onClick={
                            data.GetLocationById.locationType.displayName !==
                            LOCATION_TYPES_NAMES.property
                              ? () => {
                                  updateSelectedVirtualLocation(null);
                                  setShowEdit(true);
                                }
                              : undefined
                          }
                          icon={<EditOutlined />}
                          activeIcon={
                            <EditFilled
                              style={{
                                color: COLORS.primary[5],
                              }}
                            />
                          }
                          active={showEdit}
                          theme={ICON_BUTTON_THEME}
                        />
                        <IconButton
                          onClick={() => onClose()}
                          icon={<CloseCircleOutlined />}
                          theme={ICON_BUTTON_THEME}
                        />
                      </Space>
                    }
                    onClose={() => onClose()}
                  />
                </Col>
              </Row>
            </>
          }
        />
      }
    >
      {selectedVirtualLocation && (
        <LocationView
          show
          close={() => updateSelectedVirtualLocation(null)}
          id={selectedVirtualLocation.id}
          isMultiVirtual
        />
      )}
      <Space direction="vertical" style={STYLES.content}>
        {HOUSEKEEPING_FEATURE_FLAG &&
          roomSummary &&
          locationTypeName.toUpperCase() === 'ROOM' && (
            <LocationHousekeepingStatus
              occupancyStatus={roomSummary?.roomSummary.occupancyStatus || ''}
              cleaningStatus={roomSummary?.roomSummary.cleaningStatus || ''}
              outOfOrderDates={roomSummary?.roomSummary.outOfOrderDates || []}
              cleaningExceptionStatus={cleaningExceptionStatus || {}}
              locationId={selectedLocation.id}
              locationName={selectedLocation.longDisplayName}
            />
          )}
        {CurrentJobsFeatureFlag && (
          <CurrentJobs
            location={{ id }}
            jobList={todayJobs}
            timeZone={property?.regionalSettings?.timeZone}
            toggleRush={toggleRush}
            updateLoading={updateLoading}
            onClose={onClose}
          />
        )}
        {RESERVATION_DETAILS_FLAG &&
          canProduct([PRODUCT_ACCESS.pms_Data]) &&
          locationTypeName.toUpperCase() === 'ROOM' && (
            <ReservationDetails
              reservations={roomSummary?.roomSummary.reservations || []}
              loading={loadingRoomSummary}
            />
          )}
      </Space>
      <Tabs
        defaultActiveKey="2"
        tabBarGutter={24}
        items={items}
        tabBarStyle={STYLES.tabBar}
        style={STYLES.tabs}
      />

      <LocationForm
        open={showEdit}
        onClose={() => setShowEdit(false)}
        locationId={selectedLocation.id}
        locationName={longDisplayName}
      />
    </Drawer>
  );
}

export default memo(LocationView);
