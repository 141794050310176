import React, {
  useEffect,
  useContext,
  useState,
  useMemo,
  useCallback,
} from 'react';
import { useLazyQuery } from '@apollo/client';
import { UserAccessContext, PropertyContext, useSnack } from '@optii/shared';
import { GET_EMPLOYEE_BY_USER_ID } from '@optii/shared/queries/employee/getEmployeeByUserId';
import { Auth } from '@optii/shared/components/organisms/Authentication/AuthService';

export type EmployeeType = {
  id: string;
  propertyDetails: Array<{
    employTypeId: number;
    employTypeName: string;
    propertyId: number;
    propertyName: string;
    roles: Array<{
      deptId: number;
      deptName: string;
      id: string;
      roleId: number;
      roleName: string;
      roleStatus: string;
    }>;
  }>;
  status: string;
  userFirstName: string;
  userId: number;
  userName: string;
};

const Context = React.createContext<{
  employee: EmployeeType | boolean;
  resetContext: () => void;
  hasRole: (roleName: string) => boolean;
}>({
  employee: {} as EmployeeType,
  resetContext: () => {},
  hasRole: () => false,
});

const SYS_OPTII_ADMIN_ID = '-1';

const Provider = function ({ children }: { children: React.ReactNode }) {
  const { setSnack, Snack } = useSnack();
  const { user } = useContext(UserAccessContext.Context);
  const { property } = useContext(PropertyContext.Context);
  const [employee, setEmployee] = useState<EmployeeType | boolean>(false);
  const [getEmployee, { data, error: employeeError }] = useLazyQuery(
    GET_EMPLOYEE_BY_USER_ID,
  );

  // handle failed employee errors
  useEffect(() => {
    if (user && user.id === SYS_OPTII_ADMIN_ID) {
      setEmployee({ propertyDetails: [] } as any);
    } else if (employeeError) {
      setSnack({
        message: "An error occurred fetching user's employment",
        error: true,
        timeout: 6000,
      });
    }
  }, [employeeError]);

  useEffect(() => {
    if (
      user &&
      property &&
      user.id === (property as any).userId &&
      Auth.Instance.isTokenValid(0)
    ) {
      // When property is updated, get employment
      getEmployee({
        variables: { id: user.id },
      });
    }
  }, [property, user]);

  useEffect(() => {
    if (data && data.employee) {
      setEmployee(data.employee);
    }
  }, [data]);

  const hasRole = useCallback(
    (roleName: string) => {
      if (typeof employee === 'object' && employee.propertyDetails) {
        return employee.propertyDetails.some((property) =>
          property.roles.some((role) => role.roleName === roleName),
        );
      }
      return false;
    },
    [employee],
  );

  const contextValue = useMemo(
    () => ({
      employee,
      resetContext: () => {
        setEmployee(false);
      },
      hasRole,
    }),
    [employee, setEmployee],
  );

  return (
    <Context.Provider value={contextValue}>
      {children}
      <Snack />
    </Context.Provider>
  );
};
export const EmploymentContext = { Provider, Context };
