import { Mentions, MentionsProps } from '@optii/ui-library';
import React, {
  Dispatch,
  KeyboardEvent,
  SetStateAction,
  useCallback,
} from 'react';
import { Conversation } from '@twilio/conversations';
import { TaggedUser } from '../../types';

type MessageFieldProps = MentionsProps & {
  setValue: Dispatch<SetStateAction<string | undefined>>;
  setTaggedUsers: Dispatch<SetStateAction<TaggedUser[]>>;
  onSendMessage: () => Promise<void>;
  channel?: Conversation;
};

function MessageField(props: MessageFieldProps) {
  const { setValue, setTaggedUsers, onSendMessage, channel, ...rest } = props;

  const onKeyDown = useCallback(
    (e: KeyboardEvent<HTMLTextAreaElement>) => {
      const { key } = e;

      const isMentionsPopupOpen =
        document.getElementsByClassName('message-input-popup').length > 0;

      if (key === 'Enter' && !e.shiftKey && !isMentionsPopupOpen) {
        e.preventDefault();
        e.stopPropagation();
        onSendMessage();
      }
    },
    [onSendMessage],
  );

  const onChange = useCallback(
    (input: string) => {
      if (channel) channel.typing();

      setValue(input);

      if (!input) {
        setTaggedUsers([]);
      }
    },
    [channel, setValue, setTaggedUsers],
  );

  return (
    <Mentions
      {...rest}
      popupClassName="message-input-popup"
      filterOption={(input, item) => {
        const { label } = item as { label: string };
        return label.toLowerCase().trim().includes(input.trim().toLowerCase());
      }}
      key="key"
      data-testid="messageInput"
      onKeyDown={onKeyDown}
      onChange={onChange}
    />
  );
}

export default MessageField;
