/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { Auth } from '@optii/shared/components/organisms/Authentication/AuthService';
import { AuthActions } from 'ionic-appauth';
import { isPlatform } from '@ionic/react';
import config from 'config';
import Loading from 'components/shared/Loading';
import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom';
import { UserAccessContext } from '@optii/shared';

const UI_URL = config.REACT_APP_UI_URL;
const ImplicitContainer = styled.div`
  margin-top: 10rem;
`;

const ImplicitCallback = function ImplicitCallback(props) {
  const {
    routeProps: { location },
  } = props;
  const history = useHistory();
  const { setToken } = useContext(UserAccessContext.Context);
  useEffect(() => {
    const host = isPlatform('capacitor') ? 'optiitopcat://localhost' : UI_URL;

    Auth.Instance.handleCallback(host + location.pathname + location.search);
    const observer = Auth.Instance.addActionListener(async (action) => {
      console.info(
        '>>> [observer firing] [ImplicitCallback.js2]',
        action.action,
      );
      if (
        action.action === AuthActions.SignInSuccess ||
        action.action === AuthActions.LoadTokenFromStorageSuccess
      ) {
        setToken(action.tokenResponse.accessToken);
      }
    });

    return () => {
      Auth.Instance.removeActionObserver(observer);
    };
  }, [location]);
  useEffect(
    (_) => {
      if (Auth.Instance.session.isAuthenticated) {
        const authDashboard = Auth.Instance.getFromUri() || '/dashboard';

        history.push(authDashboard);
      }
    },
    [history, Auth.Instance.session.isAuthenticated],
  );
  return (
    <ImplicitContainer>
      <Loading loading />
    </ImplicitContainer>
  );
};
export default ImplicitCallback;
