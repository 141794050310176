import {
  Col,
  COLORS,
  ConfigProvider,
  MenuProps,
  Row,
  SPACING,
  Badge,
  Avatar,
  FONTS,
  Typography,
  Flex,
  RADIUS,
} from '@optii-solutions/ui-library';
import { CSSProperties } from 'react';
import { Link } from 'react-router-dom';

import { usePropertyTime } from '@optii/shared';
import { Channel } from '../../types';
import { SelectOption } from './types';

type MenuItem = Required<MenuProps>['items'][number];
const MESSAGE_PREVIEW_ITEM_STYLE: CSSProperties = {
  height: 70,
  paddingInline: SPACING.SIZE_MD,
  borderBottom: `1px solid ${COLORS.neutral[5]}`,
  borderRadius: RADIUS.NONE,
};

function AvatarWithBadge({
  namePreview,
  status,
  userQuantity = 1,
  isPrivateConversation,
}: {
  namePreview?: string;
  status?: boolean;
  userQuantity?: number;
  isPrivateConversation?: boolean;
}) {
  return (
    <ConfigProvider
      theme={{
        components: {
          Badge: {
            dotSize: SPACING.SIZE_DEFAULT,
          },
        },
      }}
    >
      <Badge
        dot={!isPrivateConversation ? false : status}
        count={!isPrivateConversation ? userQuantity : undefined}
        color={
          !isPrivateConversation ? COLORS.neutral[8] : COLORS.polarGreen[5]
        }
        offset={!isPrivateConversation ? [-4, 28] : [-4, 32]}
        styles={{
          indicator: {
            boxShadow: !isPrivateConversation ? undefined : '0 0 0 2px #FFFF',
            borderRadius: !isPrivateConversation ? RADIUS.RADIUS_DEFAULT : '',
          },
        }}
      >
        <Avatar
          size={{
            lg: 40,
            xs: 36,
          }}
        >
          {namePreview?.toUpperCase()}
        </Avatar>
      </Badge>
    </ConfigProvider>
  );
}

function MessageContent({
  label: author,
  lastMessage,
  unreadMessageCount,
}: Omit<Channel, 'isOptii' | 'isOnline' | 'key' | 'uniqueName'>) {
  const content = lastMessage?.content;
  const timestamp = lastMessage?.timestamp;

  const { timezone } = usePropertyTime(null);

  return (
    <>
      <Row justify="space-between" align="middle" wrap={false}>
        <Typography.Text
          style={{
            color: COLORS.neutral[9],
          }}
          strong
          ellipsis
        >
          {author}
        </Typography.Text>
        <ConfigProvider
          theme={{
            components: {
              Typography: {
                fontSize: FONTS.xSmall.size,
                colorText: COLORS.neutral[7],
              },
            },
          }}
        >
          <Flex gap={SPACING.SIZE_DEFAULT}>
            <Typography.Text>
              {timestamp?.tz(timezone)?.format('LL')}
            </Typography.Text>
            {unreadMessageCount ? (
              <Badge
                count={unreadMessageCount}
                color={COLORS.goldenPurple[5]}
                styles={{
                  indicator: {
                    paddingInline: SPACING.SIZE_DEFAULT,
                  },
                }}
              />
            ) : null}
          </Flex>
        </ConfigProvider>
      </Row>
      <Row>
        <Typography.Text
          ellipsis
          style={{
            color: COLORS.neutral[8],
          }}
        >
          {content}
        </Typography.Text>
      </Row>
    </>
  );
}

export function MessagePreview(
  { label: author, lastMessage, key, recipientIds }: Channel,
  isOnline?: boolean,
  userQuantity?: number,
  unreadMessageCount?: number,
  uniqueName?: string | null,
  employees?: SelectOption[],
  userId?: string,
): MenuItem {
  const senderFirstLetter = author?.charAt(0);

  const isPrivateConversation =
    employees
      ?.filter((item) => uniqueName?.split('_').includes(item.value))
      .filter((item) => item.value !== userId)
      .map((item) => item.label)
      .join(', ') === author && recipientIds?.length === 1;

  return {
    key,
    label: (
      <Link to={`/messages/${key}`}>
        <Row align="middle" wrap={false} gutter={SPACING.SIZE_DEFAULT}>
          <Col>
            <AvatarWithBadge
              namePreview={senderFirstLetter}
              status={isOnline}
              userQuantity={userQuantity}
              isPrivateConversation={isPrivateConversation}
            />
          </Col>
          <Col flex="auto">
            <ConfigProvider
              theme={{
                components: {
                  Typography: {
                    fontSize: FONTS.small.size,
                  },
                },
              }}
            >
              <MessageContent
                label={author}
                lastMessage={lastMessage}
                unreadMessageCount={unreadMessageCount}
              />
            </ConfigProvider>
          </Col>
        </Row>
      </Link>
    ),
    style: MESSAGE_PREVIEW_ITEM_STYLE,
  };
}
