import { COLORS, Typography } from '@optii/ui-library';
import { MinimalLocationsQueryResult } from '../../api/locations';

export function generateTreeSelectLocationsPayload({
  locations,
}: MinimalLocationsQueryResult['data'] = {}) {
  const childLocations = locations
    ?.map((location) => {
      const isParent = location?.childCount > 0;

      const locationId = isParent ? `0-${location?.id}` : location?.id;

      return {
        pId: `0-${location?.parentId}`,
        value: locationId,
        id: locationId,
        childCount: location?.childCount,
        longDisplayName: location?.longDisplayName,
        shortDisplayName: location?.shortDisplayName,
        locationTypeCode: location?.locationTypeCode,
        locationTypeName: location?.locationTypeName,
        roomTypeName: location?.roomTypeName,
        parentName: location?.parentName,
        disabled: false,
      };
    })
    .sort(
      (a, b) =>
        a.longDisplayName?.localeCompare(
          b.longDisplayName as string,
          undefined,
          {
            numeric: true,
            sensitivity: 'base',
          },
        ) as number,
    );

  const parentLocations = locations
    ?.filter((location) => location?.childCount > 0)
    .map((parentLocation) => ({
      pId: `0-${parentLocation?.id}`,
      value: parentLocation?.id,
      id: parentLocation?.id,
      childCount: parentLocation?.childCount,
      longDisplayName: parentLocation?.longDisplayName,
      shortDisplayName: parentLocation?.shortDisplayName,
      locationTypeCode: parentLocation?.locationTypeCode,
      locationTypeName: parentLocation?.locationTypeName,
      roomTypeName: parentLocation?.roomTypeName,
      parentName: parentLocation?.parentName,
      disabled: false,
    }));

  return parentLocations?.concat(childLocations as []).map((node) => ({
    ...node,
    title: (
      <>
        <Typography.Text
          style={{
            color: COLORS.neutral[9],
          }}
        >
          {node.longDisplayName}{' '}
          {node.childCount > 0 && !node?.id?.startsWith('0-') ? 'Only' : null}
        </Typography.Text>
        {node?.id?.startsWith('0-') ? (
          <Typography.Text>
            (
            {node.locationTypeName?.toLowerCase() === 'property'
              ? locations?.length
              : node.childCount + 1}
            )
          </Typography.Text>
        ) : null}
      </>
    ),
  }));
}
