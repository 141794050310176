import i18n from './i18next';

export const AUTO_ASSIGNEE_ID = -88;
export const AUTO_ASSIGNEE_USERNAME = 'autoassign';

type Employee = {
  employeeId?: number;
  userId: string | number;
  userFirstName?: string | null | undefined;
  firstName?: string;
  employeeUserFirstName?: string;
  userLastName?: string | null | undefined;
  lastName?: string;
  employeeUserLastName?: string;
  userName?: string;
  employeeUserName?: string;
};

export const AUTO_ASSIGNEE_DATA = {
  id: AUTO_ASSIGNEE_ID,
  displayName: i18n.t('jobs:Auto Assign'),
  userId: AUTO_ASSIGNEE_ID,
  userName: AUTO_ASSIGNEE_USERNAME,
  firstName: i18n.t('jobs:Auto'),
  lastName: i18n.t('jobs:Assign'),
};
export function formatUserName(user: Employee) {
  if (user.employeeId === -1) return 'Optii';

  const firstName =
    user.userFirstName || user.firstName || user.employeeUserFirstName;

  const lastName =
    user.userLastName || user.lastName || user.employeeUserLastName;
  const userName = user.userName || user.employeeUserName || 'Unknown';

  const name = firstName || lastName ? `${firstName} ${lastName}` : userName;
  return name;
}
