import { Space, Icon, Flex, SPACING } from '@optii/ui-library';
import {
  PRIORITY_ICONS,
  Priorities,
  ServiceActions,
  SelectOption,
  Statuses,
} from '@optii/global';
import i18next from 'i18next';
import { ReactNode } from 'react';

export const ACTION_OPTIONS: SelectOption[] = Object.keys(ServiceActions).map(
  (serviceAction) => ({
    label:
      serviceAction.charAt(0).toUpperCase() +
      serviceAction
        .slice(1)
        .replace(/([A-Z])/g, ' $1')
        .trim(),
    value: serviceAction,
    style: {
      borderInlineStartWidth: '1px',
      borderInlineEndWidth: '1px',
    },
  }),
);

export const PRIORITY_OPTIONS: SelectOption[] = Object.keys(Priorities).map(
  (priority) => ({
    label: priority.charAt(0).toUpperCase() + priority.slice(1),
    value: priority,
  }),
);

export const STATUS_OPTIONS: SelectOption[] = Object.keys(Statuses).map(
  (status) => ({
    label:
      status === Statuses.completed
        ? i18next.t('common:Done')
        : status.charAt(0).toUpperCase() + status.replace('_', ' ').slice(1),
    value: status,
  }),
);

export const PrioritySelectOptionRender = ({
  value,
  label,
}: {
  value?: string | number;
  label?: ReactNode;
}) => {
  const ICON_SVG = PRIORITY_ICONS[value as keyof typeof PRIORITY_ICONS];

  return (
    <Space>
      <Icon component={ICON_SVG} />
      {label}
    </Space>
  );
};

export const PrioritySelectLabelRender = ({
  value,
  label,
}: {
  label?: ReactNode;
  value?: string | number;
}) => {
  const ICON_SVG = PRIORITY_ICONS[value as keyof typeof PRIORITY_ICONS];

  return (
    <Flex align="center" gap={SPACING.SIZE_SM}>
      <Icon component={ICON_SVG} />
      <span>{label}</span>
    </Flex>
  );
};
