import React, { useCallback, useContext, useEffect } from 'react';
import { Row, Col, Grid } from '@optii-solutions/ui-library';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Conversation } from '@twilio/conversations';
import { UserAccessContext } from '@optii/shared';
import { Channels } from '../channels';
import { MessageList } from './List';
import { SearchAndCreate } from '../search/SearchAndCreate';
import { ChatContext } from '../../context/chat.context';

export function Messages() {
  const { useBreakpoint } = Grid;
  const { xs } = useBreakpoint();
  const history = useHistory();
  const match = useRouteMatch<{ id: string }>('/messages/:id');

  const {
    client,
    generalChannelId,
    setChannels,
    mapPrivateConversation,
    mapPublicConversation,
    employees,
    channels,
    setUnreadConversations,
  } = useContext(ChatContext);

  const { user } = useContext(UserAccessContext.Context);

  const onConversationAdded = useCallback(
    async (conversation: Conversation) => {
      if (
        channels.publicChannels.find(
          (channel) => channel.key === conversation.sid,
        ) ||
        channels.privateChannels.find(
          (channel) => channel.key === conversation.sid,
        )
      )
        return;

      const newUnreadMessageCount = await conversation.getUnreadMessagesCount();

      const lastMessageIndex = conversation.lastMessage?.index;

      let unreadMessageCount;

      if (conversation.createdBy === user?.id) {
        unreadMessageCount = 0;
      } else if (newUnreadMessageCount === null) {
        unreadMessageCount = lastMessageIndex ? lastMessageIndex + 1 : 0;
      } else {
        unreadMessageCount = newUnreadMessageCount;
      }

      setUnreadConversations((prev) => ({
        total: prev.total + 1,
        channels: {
          ...prev.channels,
          [conversation.sid]: unreadMessageCount,
        },
      }));

      const isPublic = (conversation.attributes as { ChannelType?: 'Public' })
        ?.ChannelType;

      if (isPublic) {
        const newConversation = await mapPublicConversation(conversation);

        setChannels((prev) => ({
          privateChannels: prev.privateChannels,
          publicChannels: prev.publicChannels
            .concat([newConversation])
            .sort((a, b) => {
              if (a.label && b.label) {
                return a.label?.localeCompare(b.label);
              }
              return 0;
            }),
        }));
      } else {
        const newConversation = await mapPrivateConversation(
          conversation,
          employees,
          user?.id,
        );

        setChannels((prev) => ({
          publicChannels: prev.publicChannels,
          privateChannels: prev.privateChannels
            .concat([newConversation])
            .sort((a, b) => {
              if (a.label && b.label) {
                return b.label.localeCompare(a.label);
              }
              return 0;
            }),
        }));
      }
    },
    [
      employees,
      mapPrivateConversation,
      mapPublicConversation,
      user?.id,
      setChannels,
      channels,
      setUnreadConversations,
    ],
  );

  const onConversationRemoved = useCallback(
    (conversation: Conversation) => {
      const isPublic = (conversation.attributes as { ChannelType?: 'Public' })
        ?.ChannelType;

      setChannels((prev) => {
        if (isPublic)
          return {
            privateChannels: prev.privateChannels,
            publicChannels: prev.publicChannels.filter(
              (publicChannel) => publicChannel.key !== conversation.sid,
            ),
          };
        return {
          publicChannels: prev.publicChannels,
          privateChannels: prev.privateChannels.filter(
            (privateChannel) => privateChannel.key !== conversation.sid,
          ),
        };
      });
      if (generalChannelId) history.push(generalChannelId);
    },
    [setChannels, history, generalChannelId],
  );

  useEffect(() => {
    if (client) {
      client.on('conversationAdded', onConversationAdded);
      client.on('conversationRemoved', onConversationRemoved);
    }
    return () => {
      client?.removeListener('conversationAdded', onConversationAdded);
      client?.removeListener('conversationRemoved', onConversationRemoved);
    };
  }, [onConversationAdded, onConversationRemoved, client]);

  return (
    <Row>
      {xs ? null : (
        <Col xs={24} sm={10} md={8} lg={6} xl={6} xxl={4}>
          <Channels />
        </Col>
      )}
      <Col xs={24} sm={14} md={16} lg={18} xl={14} xxl={14}>
        {!xs || !match ? <SearchAndCreate /> : null}

        <MessageList />
      </Col>
    </Row>
  );
}
