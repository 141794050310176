import {
  type CSSProperties,
  type ReactElement,
  type FunctionComponent,
  memo,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import { UploadOutlined, SyncOutlined, FileOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { RcFile } from 'antd/es/upload';
import { Modal } from 'antd';
import type { UploadFile, GetProp } from 'antd';
import styled from 'styled-components';

import { GET_FILE_BY_ID } from '@optii/topcat-client/queries';
import {
  SPACING,
  UploadProps,
  ConfigProvider,
  Upload,
  Button,
  Flex,
  COLORS,
  Tooltip,
} from '@optii-solutions/ui-library';
import { Session, GA_EVENTS } from '@optii/shared';
import GoogleAnalyticsClient from '@optii/shared/utils/GoogleAnalyticsClient';
import { STORE_FILE } from '@optii/shared/queries/file/storeFile';

import { UPLOAD_LIST } from './UploadList.styles';
import { type TUploadHandleFile } from '../../../types/Upload';

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

const imageTypes = [
  '.jpg',
  '.png',
  '.jpeg',
  '.gif',
  '.svg',
  '.JPG',
  '.PNG',
  '.JPEG',
  '.GIF',
  '.SVG',
];

const imageTypeMatchRegex = /\.\w{3,4}($)/;

function isFileTypeImage(name: string) {
  const [fileType] = name?.match(imageTypeMatchRegex) || '';

  return imageTypes.includes(fileType);
}

function getBase64(file: FileType): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
}

type TUploadList = {
  placeholder: string;
  shard?: string;
  handleUpload: (arg: any) => void;
  handleRemove?: (arg: TUploadHandleFile) => void;
  analytics: any;
  children?: ReactElement;
  fileList?: UploadFile<any>[];
  style?: CSSProperties;
  isView?: boolean;
  isAddEnabled?: boolean;
};

function UploadListComponent(props: TUploadList) {
  const {
    handleUpload,
    analytics,
    style,
    isView = false,
    isAddEnabled = false,
    shard,
  } = props;

  // Used to guarantee uniqueness of the elements so that this will work with multiple uploads mounted at once
  const [storeFile] = useMutation(STORE_FILE, {
    context: {
      _shard: shard,
    },
  });
  const { globalSnack } = useContext(Session);
  const { t } = useTranslation(['fields', 'jobs']);
  const [preview, setPreview] = useState<{
    visible: boolean;
    image?: string;
    fileList?: UploadFile<any>[];
  }>();

  const [fileList, setFileList] = useState<UploadFile<any>[]>();

  const [getFileListData, { loading: fileListDataLoading }] = useLazyQuery(
    GET_FILE_BY_ID,
    {
      context: {
        _shard: shard,
      },
    },
  );

  const fileUploadListRef = useRef<any[]>([]);

  useEffect(() => {
    fileUploadListRef.current = props.fileList || [];
  }, [props.fileList]);

  async function upload(file: Blob | RcFile) {
    if (file.size >= 10000000) {
      globalSnack({
        message: t('jobs:This file is too large, please try another'),
        timeout: 6000,
      });

      return;
    }

    try {
      await storeFile({
        variables: {
          file,
          name: file.name,
        },
        onCompleted({ file }) {
          if (isView) {
            fileUploadListRef.current = fileUploadListRef.current.concat(
              file.UUID,
            );

            handleUpload(fileUploadListRef.current);

            globalSnack({
              message: t('jobs:This file was uploaded successfully.'),
              timeout: 6000,
            });
          } else {
            setFileList((prev) =>
              prev?.concat({
                name: file.name,
                uid: file.UUID,
                url: file.URL,
              }),
            );
            handleUpload({
              name: file.name,
              uid: file.UUID,
            });
          }
        },
      });

      GoogleAnalyticsClient.event(
        analytics || GA_EVENTS.uploadFileUnknownSource,
      );
    } catch (err) {
      console.error('Error uploading attachment:', err);

      globalSnack({
        message: t('jobs:Could not upload attachment'),
        timeout: 5000,
        error: true,
      });
    }
  }

  async function handlePreview(file: UploadFile<any>) {
    if (isFileTypeImage(file.name)) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj as FileType);
      }

      setPreview({
        image: file.url || file.preview,
        visible: true,
      });
    }
  }

  async function handleRemove(file: UploadFile<any>) {
    const newFileList = fileList?.filter(
      (fileItem) => fileItem.url !== file.url,
    );

    setFileList(newFileList);

    fileUploadListRef.current = fileUploadListRef.current.filter(
      (item) => item !== file.uid,
    );

    file.uid &&
      props.handleRemove &&
      (await props.handleRemove({
        name: file.name,
        uid: file.uid,
      }));
  }

  const uploadProps: UploadProps = {
    name: 'attachments',
    multiple: true,
    customRequest: async (options) => {
      const { file, onSuccess } = options;

      if (typeof file !== 'string' && typeof onSuccess === 'function') {
        await upload(file);

        onSuccess(file);
      }
    },

    onRemove: handleRemove,
    onPreview: handlePreview,

    listType: 'picture',
    showUploadList: {
      showDownloadIcon: true,
    },
    fileList,

    iconRender(file) {
      return (
        <Tooltip title={file.name}>
          <FileOutlined />
        </Tooltip>
      );
    },
    isImageUrl(file) {
      return isFileTypeImage(file.name);
    },

    defaultFileList: fileList,
    style: {
      gap: SPACING.SIZE_MD,
      borderWidth: '1px',
      marginBottom: SPACING.SIZE_MD,
    },
  };

  useEffect(() => {
    async function fetchFileList() {
      const asyncFileList = props.fileList?.map(async (fileItem) => {
        const {
          data: { file },
        } = await getFileListData({
          variables: { id: fileItem },
        });

        return {
          uid: fileItem,
          url: file?.URL,
          name: file.name,
          status: isFileTypeImage(file.name) ? undefined : 'done',
        };
      });
      const fetchedFileList =
        asyncFileList && (await Promise.all(asyncFileList));

      if (fetchedFileList?.length) {
        setFileList(fetchedFileList as unknown as UploadFile<any>[]);
      }
    }

    fetchFileList();
  }, [props.fileList]);

  const NewUpload: FunctionComponent<UploadProps> = styled(Upload)`
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: center;
    min-width: 100%;
    flex: 0;
    gap: 0;
    background-color: ${COLORS.neutral[1]};
    border-radius: ${SPACING.SIZE_XS}px;
    border-color: ${COLORS.neutral[5]};
    border-width: 1px;
    border-style: solid;
    padding: ${SPACING.SIZE_XS}px;
    > .ant-upload-list.ant-upload-list-picture {
      min-width: auto;
      max-width: unset;
      display: flex;
      flex: 1;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      gap: ${SPACING.SIZE_SM}px;

      flex-wrap: wrap;
      &:before {
        display: none;
      }
      > .ant-upload-list-item-container {
        display: flex;
        &:before {
          display: none;
        }
        > .ant-upload-list-item {
          margin: 0;
          padding: 0;
          gap: 0;
          max-height: 40px;
          overflow: hidden;
          align-items: center;
          justify-content: flex-start;
          > a.ant-upload-list-item-name {
            display: none;
          }
          > a.ant-upload-list-item-thumbnail {
            min-height: 100%;
            max-height: 100%;
            display: flex;
            max-width: 40px;
            min-width: 40px;
          }
        }
      }
    }
  `;

  return (
    <ConfigProvider
      theme={{
        token: {
          size: 1,
        },
      }}
    >
      <NewUpload {...uploadProps}>
        <div style={UPLOAD_LIST.container}>
          {!isAddEnabled ? (
            <Button icon={<UploadOutlined style={UPLOAD_LIST.iconContainer} />}>
              {t('common:Upload')}
            </Button>
          ) : null}
        </div>
      </NewUpload>

      {fileListDataLoading ? (
        <Flex
          align="flex-start"
          style={{
            padding: SPACING.SIZE_MD,
          }}
        >
          <SyncOutlined />
        </Flex>
      ) : null}

      <Modal
        open={preview?.visible}
        footer={null}
        onCancel={() =>
          setPreview((...previousState) => ({
            ...previousState,
            visible: false,
          }))
        }
      >
        <img
          alt={t('common:Preview file')}
          style={{ width: '100%' }}
          src={preview?.image}
        />
      </Modal>
    </ConfigProvider>
  );
}

export const UploadList = memo(UploadListComponent);
