import { Button, Col, Typography } from '@optii-solutions/ui-library';

import { GetJobByIdQuery } from '@optii/jobs/api/jobs';
import { useContext } from 'react';
import { UserAccessContext } from '@optii/shared/index';
import { getEnvironment } from '@optii/shared/utils/config';
import {
  AUTO_ASSIGNEE_ID,
  JOB_ADVANCED_STATUSES,
  JOB_STATUSES,
} from '../JobForm/JobForm.constants';

type TFulfillButtons = {
  setShowCancelJobConfirmation?: (a: boolean) => void;
  t: (a: string) => string;
  job: GetJobByIdQuery['job'];
  handleTakeJob?: (a?: any) => void;
  handleStartJob?: (a?: any) => void;
  handleTakeAndStartJob?: (a?: any) => void;
  handlePauseJob?: (a?: any) => void;
  handleCompleteJob?: (a?: any) => void;
  handleReopenJob?: (a?: any) => void;
  handleCompleteFailJob?: (a?: any) => void;
  handleCompletePassJob?: (a?: any) => void;
  user?: {
    id: string;
  };
};


const getButtons = ({
  setShowCancelJobConfirmation,
  handleTakeJob,
  handleStartJob,
  handleTakeAndStartJob,
  handlePauseJob,
  handleCompleteJob,
  handleReopenJob,
  handleCompleteFailJob,
  handleCompletePassJob,
  t,
  job,
  user,
}: TFulfillButtons) => {
  const hasAssignee =
    job?.assignee && Number(job?.assignee?.id) !== AUTO_ASSIGNEE_ID;
  const isAssignedCurrentUser = hasAssignee && job?.assignee?.id === user?.id;

  return {
    new: isAssignedCurrentUser ? (
      <>
        <Col>
          <Button
            type="text"
            size="large"
            onClick={() => {
              if (typeof setShowCancelJobConfirmation === 'function') {
                setShowCancelJobConfirmation(true);
              }
            }}
          >
            {t('jobs:Cancel Job')}
          </Button>
        </Col>
        <Col>
          <Button
            type="primary"
            size="large"
            onClick={() => {
              if (typeof handleStartJob === 'function') {
                handleStartJob({ job });
              }
            }}
          >
            {t('jobs:Start')}
          </Button>
        </Col>
      </>
    ) : (
      <>
        <Col>
          <Button
            type="text"
            size="large"
            style={{
              height: 'auto',
              whiteSpace: 'normal',
              maxWidth: '120px',
            }}
            onClick={() => {
              if (typeof setShowCancelJobConfirmation === 'function') {
                setShowCancelJobConfirmation(true);
              }
            }}
          >
            {t('jobs:Cancel Job')}
          </Button>
        </Col>
        <Col>
          <Button
            size="large"
            style={{
              height: 'auto',
              whiteSpace: 'normal',
              maxWidth: '140px',
            }}
            onClick={() => {
              if (typeof handleTakeAndStartJob === 'function') {
                handleTakeAndStartJob({ job });
              }
            }}
          >
            {t('jobs:Take and Start')}
          </Button>
        </Col>
        {hasAssignee ? (
          <Col>
            <Button
              type="primary"
              size="large"
              onClick={() => {
                if (typeof handleStartJob === 'function') {
                  handleStartJob({ job });
                }
              }}
            >
              {t('jobs:Start')}
            </Button>
          </Col>
        ) : (
          <Col>
            <Button
              type="primary"
              size="large"
              onClick={() => {
                if (typeof handleTakeJob === 'function') {
                  handleTakeJob({ job });
                }
              }}
            >
              {t('jobs:Take')}
            </Button>
          </Col>
        )}
      </>
    ),
    inProgress: (
      <>
        <Col>
          <Button
            type="text"
            size="large"
            onClick={() => {
              if (typeof setShowCancelJobConfirmation === 'function') {
                setShowCancelJobConfirmation(true);
              }
            }}
          >
            {t('jobs:Cancel Job')}
          </Button>
        </Col>
        <Col>
          <Button
            size="large"
            onClick={() => {
              if (typeof handlePauseJob === 'function') {
                handlePauseJob({ job });
              }
            }}
          >
            {t('jobs:Pause')}
          </Button>
        </Col>
        <Col>
          <Button
            type="primary"
            size="large"
            onClick={() => {
              if (typeof handleCompleteJob === 'function') {
                handleCompleteJob({ job });
              }
            }}
          >
            {t('jobs:Complete')}
          </Button>
        </Col>
      </>
    ),
    inProgressInspection: (
      <>
        <Col>
          <Button
            type="text"
            size="large"
            onClick={() => {
              if (typeof setShowCancelJobConfirmation === 'function') {
                setShowCancelJobConfirmation(true);
              }
            }}
          >
            {t('jobs:Cancel Job')}
          </Button>
        </Col>
        <Col>
          <Button
            size="large"
            onClick={() => {
              if (typeof handleCompleteFailJob === 'function') {
                handleCompleteFailJob();
              }
            }}
          >
            {t('jobs:Complete & Fail')}
          </Button>
        </Col>
        <Col>
          <Button
            type="primary"
            size="large"
            onClick={() => {
              if (typeof handleCompletePassJob === 'function') {
                handleCompletePassJob();
              }
            }}
          >
            {t('jobs:Complete & Pass')}
          </Button>
        </Col>
      </>
    ),
    cancelled: (
      <Col>
        <Button
          type="primary"
          size="large"
          onClick={() => {
            if (typeof handleReopenJob === 'function') {
              handleReopenJob({ job });
            }
          }}
        >
          {t('jobs:Reopen')}
        </Button>
      </Col>
    ),
  };
};

type TJobDetailsFulfillProps = {
  job: GetJobByIdQuery['job'];
  setShowCancelJobConfirmation?: (a: boolean) => void;
  t: (a: string) => string;
  handleTakeJob?: (a?: any) => void;
  handleStartJob?: (a?: any) => void;
  handleTakeAndStartJob?: (a?: any) => void;
  handlePauseJob?: (a?: any) => void;
  handleCompleteJob?: (a?: any) => void;
  handleReopenJob?: (a?: any) => void;
  handleCompleteFailJob?: (a?: any) => void;
  handleCompletePassJob?: (a?: any) => void;
};

export function JobDetailsFulfillButtons(props: TJobDetailsFulfillProps) {
  const {
    setShowCancelJobConfirmation,
    t,
    job,
    handleTakeJob,
    handleStartJob,
    handleTakeAndStartJob,
    handlePauseJob,
    handleCompleteJob,
    handleReopenJob,
    handleCompletePassJob,
    handleCompleteFailJob,
  } = props;

  const user = useContext(UserAccessContext.Context).user as { id: string };

  const CancelAndStart = getButtons({
    setShowCancelJobConfirmation,
    handleTakeJob,
    handleStartJob,
    handleTakeAndStartJob,
    t,
    job,
    user,
  }).new;

  const PauseComplete = getButtons({
    setShowCancelJobConfirmation,
    handlePauseJob,
    handleCompleteJob,
    t,
    job,
  }).inProgress;

  const PauseCompleteInspection = getButtons({
    setShowCancelJobConfirmation,
    handleCompletePassJob,
    handleCompleteFailJob,
    t,
    job,
  }).inProgressInspection;

  const Reopen = getButtons({
    handleReopenJob,
    t,
    job,
  }).cancelled;

  const isCorrectionInspection = (['inspection', 'check'].includes(job.action as string) && job.parentJobId);

  const buttons =
    JOB_ADVANCED_STATUSES.pending &&
    JOB_ADVANCED_STATUSES.new &&
    JOB_ADVANCED_STATUSES.archived
      ? {
          [JOB_STATUSES.onHold.value]: CancelAndStart,
          [JOB_ADVANCED_STATUSES.pending.value]: CancelAndStart,
          [JOB_ADVANCED_STATUSES.new.value]: CancelAndStart,
          [JOB_STATUSES.inProgress.value]:
            isCorrectionInspection
              ? PauseCompleteInspection
              : PauseComplete,
          [JOB_STATUSES.cancelled.value]: Reopen,
          [JOB_ADVANCED_STATUSES.archived.value]: Reopen,
          [JOB_STATUSES.completed.value]: Reopen,
        }
      : {};

  const Buttons = () =>
    job?.status !== 'queued' ? buttons[job?.status] : null;

  return <Buttons />;
}
