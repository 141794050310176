import type { CSSProperties } from 'react';

import { Dayjs } from 'dayjs';
import type {
  GetJobByIdQuery,
  JobAction,
  JobStatus,
  JobType,
  Maybe,
} from '../../api/jobs';

export type TJobDetailsStyles = {
  [key: string]: CSSProperties;
};

export type TJobDetailsAttachmentsData = string[];

export type TJobDetailsTabsData = {
  id: string;
  status: JobStatus;
  action?: Maybe<JobAction>;
  type: JobType;
  notes: any;
  activity: any;
  checklistTemplate: GetJobByIdQuery['job']['checklistTemplate'];
  checklists: GetJobByIdQuery['job']['checklists'];
};

export type TJobDetailsReservationDetails = {
  data: TJobDetailsReservationDetailsData[];
  t: (
    a: string,
    b?: {
      estimatedArrivalTime?: string;
      estimatedDepartureTime?: string;
      adults?: number;
      children?: number;
    },
  ) => string;
};

export enum TJobDetailsReservationDetailsDataStatusType {
  'InHouse' = 'In House',
  'Arrival' = 'Arrival',
  'Departure' = 'Departure',
}

export enum TJobDetailsReservationDetailsDataReservationStatus {
  'Unknown' = 'Unknown',
  'Reserved' = 'Reserved',
  'Cancelled' = 'Cancelled',
  'CheckedIn' = 'Checked In',
  'CheckedOut' = 'Checked Out',
}

export type Preference = {
  type: string;
  value: string;
  description: string;
};

export type TJobDetailsReservationDetailsDataGuest = {
  id: string;
  country?: string;
  externalGuestProfileId?: string;
  preferences?: [Preference];
  firstName?: string;
  lastName?: string;
  title?: string;
  type: string;
};

export type Note = {
  note: string;
};

export type TJobDetailsReservationDetailsData = {
  id: string;
  attributes: any;
  externalReservationId: string;
  reservationStatus: TJobDetailsReservationDetailsDataReservationStatus;
  guests: TJobDetailsReservationDetailsDataGuest[];
  displayName: string;
  arrivalDate: string;
  departureDate: string;
  estimatedArrivalTime: string;
  estimatedDepartureTime: string;
  noOfAdults: number;
  noOfChildren: number;
  isDeleted: boolean;
  notes: Note[];
  preferences: Preference[];
  version: string;
  statusLabel: TJobDetailsReservationDetailsDataStatusType;
};

export type THandleRemoveFile = {
  file: string;
};

export type THandleAddFile = {
  files: string[];
};

export type THandleAsset = {
  asset: string;
};

export type THandleLocation = {
  location: string;
};

export type THandleUpdateJob = {
  job: GetJobByIdQuery['job'];
  newJob: any;
};

export type THandleCancelJob = {
  job: GetJobByIdQuery['job'];
};

export type THandleReopenJob = {
  job: GetJobByIdQuery['job'];
};

export type THandleCompleteJob = {
  job: GetJobByIdQuery['job'] & {
    jobTemplate?: any;
  };
};

export type TIsChecklistImcomplete = (jobData: THandleCompleteJob) => boolean;

export type THandleTakeJob = {
  job: GetJobByIdQuery['job'];
  user: any;
};

export type THandleTakeAndStartJob = {
  job: GetJobByIdQuery['job'];
  user: any;
};

export type THandlePauseJob = {
  job: GetJobByIdQuery['job'];
};

export type THandleStartJob = {
  job: GetJobByIdQuery['job'];
};

export type TCancelJobConfirmation<T> = {
  hide: () => void;
  onCancelJob: (a: GetJobByIdQuery['job']) => void;
  values: GetJobByIdQuery['job'];
  t: (a: string, b?: T) => string;
};

export type TGetJobDetailsAction = {
  action?: Maybe<JobAction>;
  t: (
    a: string,
    b: {
      actionLabel: string;
    },
  ) => string;
};

export type TGetJobDetailsStatus = {
  status: JobStatus;
  t: (
    a: string,
    b: {
      statusLabel: string;
    },
  ) => string;
};

export type TGetJobSource = {
  isProject?: boolean;
  isRepeating?: boolean;
  isService?: boolean;
  isHousekeeping?: boolean;
};

export type TJobSources = 'service' | 'housekeeping' | 'repeating' | 'project';

export type TTransformTakeJobData = {
  user: any;
};

export type TTransformTakeAndStartJobData = {
  user: any;
};

export type TTransformUpdateJobData = {
  job: GetJobByIdQuery['job'];
  newJob: GetJobByIdQuery['job'];
};

export type TCompletableChecklistTask = {
  dataCheckboxChecked: boolean;
  required: boolean;
};

export type TCompletableChecklistTasks = TCompletableChecklistTask[];

export type TJobDetailsCardCustomStyles<Type> = {
  [n in keyof Type | 'TimePicker']?: CSSProperties;
};

export type LegacyLog = {
  action: string;
  createdAt: number;
  details: string;
  recordedAt?: Dayjs;
  employee: {
    employeeId: number;
    employeeSelf: string;
    employeeUserName: string;
    employeeUserFirstName: string | undefined;
    employeeUserLastName: string | undefined;
  };
  legacy: boolean;
  id: string;
  itemId: number;
  modifier1?: string | null;
  modifier2?: string | null;
  object: string;
};
