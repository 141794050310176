type AssetList = {
  assetType: {
    id: string;
    showNotes: boolean;
  };
  showNotes: boolean;
};

export function assetListWithNotes(assetList: any[]) {
  const assetTypes = {} as { [key: string]: any };
  let hasAssetsWithTheSameAssetType = false;
  assetList.forEach((asset, index) => {
    const assetTypeId = asset.assetType.id;
    assetTypes[assetTypeId] = assetTypes[assetTypeId] || { assetsIndex: [] };
    assetTypes[assetTypeId].assetsIndex.push(index);
    if (assetTypes[assetTypeId].assetsIndex.length > 1) {
      hasAssetsWithTheSameAssetType = true;
    }
  });
  if (!hasAssetsWithTheSameAssetType) return assetList;

  Object.keys(assetTypes).forEach((assetTypeId) => {
    if (assetTypes[assetTypeId].assetsIndex.length > 1) {
      assetTypes[assetTypeId].assetsIndex.forEach((index: number) => {
        assetList[index].showNotes = true;
      });
    }
  });
  return assetList;
}
