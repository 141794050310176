import {
  Segmented as AntSegmented,
  ConfigProvider,
  SegmentedProps,
  ThemeConfig,
} from 'antd';
import React from 'react';

type Props = Omit<SegmentedProps, 'ref'> & {
  theme?: ThemeConfig;
};

export function Segmented(props: Props) {
  const { theme, ...rest } = props;
  return (
    <ConfigProvider theme={theme}>
      <AntSegmented {...rest} />
    </ConfigProvider>
  );
}
