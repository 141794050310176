import React, { useContext } from 'react';
import {
  Button,
  Flex,
  Tooltip,
  Typography,
  SPACING,
  FormInstance,
  COLORS,
  FONTS,
} from '@optii/ui-library';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { FormContext } from './context';

type Props = {
  form: FormInstance;
};

export function PredictiveDueTime({ form }: Props) {
  const { suggestion, suggestionLoading: loading } = useContext(FormContext);

  const { t } = useTranslation(['jobs']);

  return (
    <Flex
      align="center"
      gap={SPACING.SIZE_SM}
      style={{
        marginBottom: SPACING.SIZE_L,
      }}
    >
      <Typography.Text>Suggested:</Typography.Text>

      <Button
        disabled={!suggestion}
        size="small"
        type="default"
        loading={loading}
        onClick={() => {
          form.setFieldValue('doBy', suggestion);
        }}
        style={{
          fontWeight: 500,
          color: COLORS.neutral[8],
        }}
        theme={{
          components: {
            Button: {
              fontSize: FONTS.small.size,
              colorBgContainer: COLORS.neutral[3],
            },
          },
        }}
      >
        {suggestion?.format('LT') || 'No suggestion'}
      </Button>
      <Tooltip
        title={t('jobs:Suggested due time based on similar previous jobs')}
        placement="bottom"
      >
        <InfoCircleOutlined
          style={{
            fontSize: 20,
            color: COLORS.primary[5],
          }}
          aria-label="Info about suggested due time"
        />
      </Tooltip>
    </Flex>
  );
}
