import { createContext, Dispatch, SetStateAction } from 'react';
import { Client } from '@twilio/conversations';

type UnreadConversation = {
  total: number;
  channels: {
    [key: string]: number;
  };
};

type SessionProps = {
  client: Client | null;
  user: {
    id: string;
  };
  prevPage: boolean;
  isMobileMenu: boolean;
  setPrevPage: Dispatch<SetStateAction<boolean>>;
  toggleMobileMenu: Dispatch<SetStateAction<boolean>>;
  property: any;
  setProperty: Dispatch<SetStateAction<any>>;
  globalSnack: (v: unknown) => void;
  token: any;
  unreadConversations: UnreadConversation;
  updateUnreadConversations: () => Promise<void>;
  setUnreadConversations: Dispatch<SetStateAction<UnreadConversation>>;
  generalChannelId: string;
};

export const Session = createContext<SessionProps>({
  user: { id: '' },
  prevPage: false,
  isMobileMenu: false,
  setPrevPage: () => {},
  toggleMobileMenu: () => {},
  property: false,
  setProperty: () => {},
  globalSnack: (_: unknown) => {},
  token: {},
  client: null,
  unreadConversations: {
    total: 0,
    channels: {},
  },
  updateUnreadConversations: () => Promise.resolve(undefined),
  setUnreadConversations: () => {},
  generalChannelId: '',
});
