import type { RadioGroupProps, RadioProps, ThemeConfig } from 'antd';
import { Radio as AntRadio, ConfigProvider } from 'antd';

export type Props = RadioProps & {
  theme?: ThemeConfig;
};

export type GroupProps = RadioGroupProps & {
  theme?: ThemeConfig;
};

export function Radio(props: Props) {
  const { theme, ...rest } = props;
  return (
    <ConfigProvider theme={theme}>
      <AntRadio {...rest}>{props.children}</AntRadio>
    </ConfigProvider>
  );
}

export function RadioGroup(props: GroupProps ) {
  const { theme, ...rest } = props;
  return (
    <ConfigProvider theme={theme}>
      <AntRadio.Group {...rest}>{props.children}</AntRadio.Group>
    </ConfigProvider>
  );
}

export function RadioButton(props: Props) {
  const { theme, ...rest } = props;
  return (
    <ConfigProvider>
      <AntRadio.Button {...rest}>{props.children}</AntRadio.Button>
    </ConfigProvider>
  );
}