/* eslint-disable no-underscore-dangle */

declare global {
  interface Window {
    _env_: NodeJS.ProcessEnv;
  }
}

const isLocal = typeof window._env_ === 'undefined';

export const context = isLocal ? process.env : window._env_;

type Environment = 'DEV' | 'TEST' | 'PROD';
function getGraphQLEnv() {
  const graphQLServer = context.REACT_APP_GRAPH_SERVER;
  if (graphQLServer?.match(/\.dev\./)) {
    return 'DEV';
  }
  if (graphQLServer?.match(/\.test\./)) {
    return 'TEST';
  }
  return 'PROD';
}

export const isEnvironment = (env: Environment[] | Environment) => {
  const currentEnvironment = isLocal ? 'DEV' : getGraphQLEnv();
  if (Array.isArray(env)) {
    return env.includes(currentEnvironment);
  }

  return env === currentEnvironment;
};

export const ENV = getGraphQLEnv();
