/* eslint-disable */
import { isPlatform } from '@ionic/react';
import {
  AuthService,
  DefaultBrowser,
  Browser,
  AuthActionBuilder,
  EndSessionRequestJson,
  EndSessionRequest,
} from 'ionic-appauth';
import {
  StorageBackend,
  Requestor,
  AuthorizationNotifier,
  TokenResponse,
  AuthorizationRequestJson,
  AuthorizationRequest,
  DefaultCrypto,
  GRANT_TYPE_AUTHORIZATION_CODE,
  TokenRequestJson,
  TokenRequest,
  AuthorizationResponse,
  AuthorizationError,
  LocalStorageBackend,
  JQueryRequestor,
} from '@openid/appauth';
import { CapacitorSecureStorage } from 'ionic-appauth/lib/capacitor';

import config from '@optii/shared/utils/config';

import { OptiiAuthorizationRequestHandler } from '@optii/shared/components/organisms/Authentication/OptiiAuthRequestHandler';
import { OptiiEndSessionHandler } from '@optii/shared/components/organisms/Authentication/OptiiEndSessionHandler';
import { AjaxRequestor } from '@optii/shared/components/organisms/Authentication/AjaxService';
import { OptiiCapacitorBrowser } from '@optii/shared/components/organisms/Authentication/OptiiCapacitorBrowser';

const AUTH_EXPIRY_BUFFER = 30 * 60 * -1; // 30 mins in seconds
const { sessionStorage } = window;
const OKTA_URL = config.REACT_APP_OKTA_URL;
const OKTA_ID = config.REACT_APP_OKTA_ID;
const UI_URL = config.REACT_APP_UI_URL;
const TOKEN_RESPONSE_KEY = 'token_response';
// Used to store and retrieve the uri when the user needs to login
const referKey = 'optiiSecureRouterReferrer';
export const AUTHORIZATION_RESPONSE_KEY = 'auth_response';
let isPending: any = false;

// Had do extend this. This basically surfaces all of the auth related functionality
// Most of this is copied from a specific version of ionic-appauth.
// I had to reference a single version because during development there we many breaking changes
// Obviously, it would be ideal if we could use a more stable library, however there
//  were no other options available for capacitor, and I was pointed to this library
//  by an okta developer.
// You can find the version we point to here: https://github.com/wi3land/ionic-appauth/commit/4d3dde1d5030ee7f2a36f8a8189bf135cdcd5066
class OptiiAuth extends AuthService {
  protected requestHandler: OptiiAuthorizationRequestHandler;

  protected endSessionHandler: OptiiEndSessionHandler;

  constructor(
    protected browser: Browser = new DefaultBrowser(),
    protected storage: StorageBackend = new LocalStorageBackend(),
    protected requestor: Requestor = new JQueryRequestor(),
  ) {
    super(browser, storage, requestor);

    this.requestHandler = new OptiiAuthorizationRequestHandler(
      browser,
      storage,
    );
    this.endSessionHandler = new OptiiEndSessionHandler(browser, undefined);
    this.setupAuthorizationNotifier();
  }

  protected async performEndSessionRequest(): Promise<void> {
    if (this.session.token != undefined) {
      const requestJson: EndSessionRequestJson = {
        postLogoutRedirectURI: this.authConfig.end_session_redirect_url,
        idTokenHint: this.session.token.idToken || '',
      };
      const request: EndSessionRequest = new EndSessionRequest(requestJson);
      await this.endSessionHandler.performEndSessionRequest(
        await this.configuration,
        request,
      );

      this.EndSessionCallBack();
    } else {
      this.EndSessionCallBack();
    }
  }

  protected async EndSessionCallBack() {
    this.browser?.closeWindow();
    this.storage.removeItem(TOKEN_RESPONSE_KEY);
    this.notifyActionListers(AuthActionBuilder.SignOutSuccess());
  }

  protected setupAuthorizationNotifier() {
    const notifier = new AuthorizationNotifier();
    this.requestHandler.setAuthorizationNotifier(notifier);
    notifier.setAuthorizationListener((request, response, error) => {
      this.onAuthorizationNotification(request, response, error);
    });
  }

  protected onAuthorizationNotification(
    request: AuthorizationRequest,
    response: AuthorizationResponse | null,
    error: AuthorizationError | null,
  ) {
    const codeVerifier: string | undefined =
      request.internal != undefined && this.authConfig.pkce
        ? request.internal.code_verifier
        : undefined;

    if (response != null) {
      this.requestAccessToken(response.code, codeVerifier);
    } else if (error != null) {
      throw new Error(error.errorDescription);
    } else {
      throw new Error('Unknown Error With Authentication');
    }
  }

  protected async requestAccessToken(
    code: string,
    codeVerifier?: string,
  ): Promise<any> {
    const requestJSON: TokenRequestJson = {
      grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
      code,
      refresh_token: undefined,
      redirect_uri: this.authConfig.redirect_url,
      client_id: this.authConfig.client_id,
      extras: codeVerifier
        ? {
            code_verifier: codeVerifier,
          }
        : {},
    };

    try {
      const token: TokenResponse = await this.tokenHandler.performTokenRequest(
        await this.configuration,
        new TokenRequest(requestJSON),
      );
      await this.storage.setItem(
        TOKEN_RESPONSE_KEY,
        JSON.stringify(token.toJson()),
      );

      this.notifyActionListers(AuthActionBuilder.SignInSuccess(token));
      return token;
    } catch (err) {}
  }

  protected async AuthorizationCallBack(url: string) {
    await this.storage.setItem(AUTHORIZATION_RESPONSE_KEY, url);
    this.browser?.closeWindow() && this.browser.closeWindow();
    return this.requestHandler.completeAuthorizationRequestIfPossible();
  }

  public clearSession(action: any) {
    this.storage.clear();
  }

  public async getValidToken(
    buffer: number = AUTH_EXPIRY_BUFFER,
  ): Promise<TokenResponse> {
    const oldToken = this.session.token && this.session.token.toString();
    if (this.session.token) {
      const needToRenew = !this.session.token.isValid(buffer);
      if (needToRenew) {
        let result;
        if (isPending) {
          result = await isPending;
        } else {
          try {
            if (isPlatform('capacitor')) {
              this.storage.clear();
              this.notifyActionListers(
                AuthActionBuilder.RefreshFailed(
                  "Can't yet renew tokens in cap",
                ),
              );
              throw "Can't yet renew tokens in cap";
            }
            isPending = this.renewToken();
            result = await isPending;
          } catch (err) {
            // do nothing with the error
          }
          // Reasonable boundary. Worse case, we make extra token calls
          setTimeout(() => {
            isPending = false;
          }, 5000);
        }
        const newToken = result;

        if (newToken && newToken.accessToken) {
          return newToken;
        }
      } else {
        return this.session.token;
      }
    }
    throw new Error('No token obtained');
  }

  // Get a reference to the uri a user is attempting to access before being forced to login
  public getFromUri() {
    const referrerKey = referKey;
    const location = sessionStorage.getItem(referrerKey) || '';
    sessionStorage.removeItem(referrerKey);
    return location;
  }

  // Store a reference to the uri a user is attempting to access before being forced to login
  public setFromUri(fromUri: string) {
    sessionStorage.setItem(referKey, fromUri);
  }

  // check if a token is valid (within the margins of a buffer)
  public isTokenValid(buffer = AUTH_EXPIRY_BUFFER) {
    return this.session.token && this.session.token.isValid(buffer);
  }

  // Kickoff a token renewal, okta doesn't support refreshes for our app type
  // This is similar to performAuthorizationRequest
  public async renewToken(auth_extras: any = {}): Promise<any> {
    const requestJson: AuthorizationRequestJson = {
      response_type: AuthorizationRequest.RESPONSE_TYPE_CODE,
      client_id: this.authConfig.client_id,
      redirect_uri: this.authConfig.redirect_url,
      scope: this.authConfig.scopes,
      extras: {
        prompt: 'none',
        response_mode: 'okta_post_message',
        scope: 'openid',
        display: null,
        ...auth_extras,
      },
    };

    const request: any = new AuthorizationRequest(
      requestJson,
      new DefaultCrypto(),
      this.authConfig.pkce,
    );

    if (this.authConfig.pkce) await request.setupCodeVerifier();
    const authResponse: any = await this.requestHandler.completeRenewal(
      await this.configuration,
      request,
    );

    if (authResponse.error) {
      this.storage.clear();
      this.notifyActionListers(
        AuthActionBuilder.RefreshFailed(authResponse.error),
      );
      throw 'Error renewing token';
    }
    return this.requestAccessToken(
      authResponse.code,
      request.internal.code_verifier,
    );
  }

  public handleCallback(callbackUrl: string): void {
    if (callbackUrl.indexOf(this.authConfig.redirect_url) === 0) {
      this.AuthorizationCallBack(callbackUrl).catch((response) => {
        this.notifyActionListers(AuthActionBuilder.SignInFailed(response));
      });
    }

    if (callbackUrl.indexOf(this.authConfig.end_session_redirect_url) === 0) {
      this.EndSessionCallBack().catch((response) => {
        this.notifyActionListers(AuthActionBuilder.SignOutFailed(response));
      });
    }
  }

  protected async performAuthorizationRequest(
    auth_extras?: any,
  ): Promise<void> {
    const requestJson: AuthorizationRequestJson = {
      response_type: AuthorizationRequest.RESPONSE_TYPE_CODE,
      client_id: this.authConfig.client_id,
      redirect_uri: this.authConfig.redirect_url,
      scope: this.authConfig.scopes,
      extras: auth_extras,
    };

    const request = new AuthorizationRequest(
      requestJson,
      new DefaultCrypto(),
      this.authConfig.pkce,
    );

    if (this.authConfig.pkce) await request.setupCodeVerifier();

    return this.requestHandler.performAuthorizationRequest(
      await this.configuration,
      request,
    );
  }
}

// This class initializes config for the OptiiAuth class above.
export class Auth {
  private static authService: OptiiAuth | undefined;

  private static buildAuthInstance() {
    const authService = new OptiiAuth(
      new OptiiCapacitorBrowser(),
      new CapacitorSecureStorage(),
      new AjaxRequestor(),
    );
    authService.authConfig = {
      client_id: OKTA_ID,
      server_host: `${OKTA_URL}/oauth2/default`,
      redirect_url: isPlatform('capacitor')
        ? 'optiitopcat://localhost/implicit/callback'
        : `${window.location.origin}/implicit/callback`,
      end_session_redirect_url: isPlatform('capacitor')
        ? 'optiitopcat://localhost/login'
        : `${UI_URL}/login`,
      scopes: 'openid',
      pkce: true,
    };
    return authService;
  }

  public static get Instance(): OptiiAuth {
    if (!this.authService) {
      this.authService = this.buildAuthInstance();
    }

    return this.authService;
  }
}
