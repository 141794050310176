import React, { useState } from 'react';
import {
  Button,
  COLORS,
  ConfigProvider,
  Divider,
  Flex,
  FONTS,
  FormInstance,
  FormItem,
  SPACING,
  FormItemProps,
  Skeleton,
  TreeSelect,
} from '@optii/ui-library';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { Location, useMinimalLocationsQuery } from '../../api/locations';
import { generateTreeSelectLocationsPayload } from '../utils/locations';

type DropdownRenderProps = {
  menu: React.ReactElement;
  options?: LocationOption[];
  form: FormInstance;
  searchValue: string;
  isJob: boolean;
  fieldName?: string | string[];
};

type LocationOption = {
  title: JSX.Element;
  pId: string;
  value: string | undefined;
  id: string | undefined;
  childCount: number;
  longDisplayName: string | null | undefined;
  locationTypeName: string | null | undefined;
  shortDisplayName: string | null | undefined;
  disabled?: boolean;
};

type LocationIdType = {
  pId: string;
  id: string;
};

const SEARCH_CRITERIA = [
  'longDisplayName',
  'shortDisplayName',
  'parentName',
  'locationTypeCode',
  'locationTypeName',
  'roomTypeName',
];

function filterTreeNode(input: string, option: unknown) {
  for (const iterator of SEARCH_CRITERIA) {
    if (
      (option as Location)[iterator as keyof Location]
        ?.toString()
        .toLowerCase()
        .includes(input.toLowerCase())
    )
      return true;
  }
  return false;
}

function findAllChildren(
  rootPid: string,
  data: LocationIdType[],
  ids: string[] = [],
) {
  data
    .filter((child) => child.pId === rootPid)
    .map((item) => {
      ids.push(item.id);
      findAllChildren(item.id, data, ids);
      return {
        id: item.id,
      };
    });

  return ids?.filter((item) => !item?.startsWith('0-'));
}

function dropdownRender({
  menu,
  options,
  form,
  searchValue,
  fieldName = 'locations',
  isJob = false,
}: DropdownRenderProps) {
  const selectableLocations = options
    ?.filter(
      (item) =>
        filterTreeNode(searchValue, item) &&
        !item?.disabled &&
        !item.id?.startsWith('0-'),
    )
    .map((item) =>
      isJob
        ? {
            type: item.locationTypeName,
            name: item.longDisplayName,
            id: item.id,
          }
        : item.id,
    );

  const selectedLocations = form.getFieldValue(fieldName);

  const deselectLocations = options?.filter((option) =>
    filterTreeNode(searchValue, option) &&
    !option.id?.startsWith('0-') &&
    !isJob
      ? selectedLocations?.includes(option.id)
      : selectedLocations
          .map((item: { id: string }) => item.id)
          .includes(option.id),
  );

  return (
    <div
      onMouseDown={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <ConfigProvider
        theme={{
          components: {
            Button: {
              paddingInline: SPACING.NONE,
              colorLink: COLORS.primary[4],
              colorLinkHover: COLORS.primary[5],
              colorLinkActive: COLORS.primary[5],
              fontSize: FONTS.small.size,
            },
          },
        }}
      >
        <Flex
          align="center"
          justify="flex-end"
          style={{
            marginRight: SPACING.SIZE_MD,
          }}
        >
          <Button
            type="link"
            onClick={(e) => {
              form.setFieldValue(
                fieldName,
                selectedLocations.concat(selectableLocations),
              );
              e.stopPropagation();
            }}
          >
            {
              i18next.t('common:Select all ({{count}})', {
                count: selectableLocations?.length,
              }) as string
            }
          </Button>
          <Divider type="vertical" />
          <Button
            type="link"
            onClick={(e) => {
              form.setFieldValue(
                fieldName,
                selectedLocations.filter(
                  (location: { id: string }) =>
                    !deselectLocations
                      ?.map((item) => item.id)
                      .includes(location.id),
                ),
              );
              e.stopPropagation();
            }}
          >
            {
              i18next.t('common:Deselect all ({{count}})', {
                count: deselectLocations?.length,
              }) as string
            }
          </Button>
        </Flex>
        {menu}
      </ConfigProvider>
    </div>
  );
}

type Props = {
  form: FormInstance;
  fieldName?: string | string[];
  normalize?: FormItemProps['normalize'];
  propertyId?: string;
  isJob: boolean;
  label?: string;
};

export function SelectLocations({
  form,
  fieldName = 'locations',
  normalize,
  propertyId,
  isJob,
  label,
}: Props) {
  const { t } = useTranslation(['common', 'jobs']);
  const [searchValue, setSearchValue] = useState('');

  const { data, loading } = useMinimalLocationsQuery({
    context: {
      _shard: propertyId,
    },
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',

    onError: (error) => {
      console.error(error);
    },
  });

  const options = generateTreeSelectLocationsPayload(data);

  const rootKey = options?.find(
    (location) => location.locationTypeName?.toLowerCase() === 'property',
  )?.id as string;

  return (
    <FormItem
      name={fieldName}
      required
      label={label || t('common:Locations')}
      rules={[
        {
          required: true,
          message: t('common:Please select a location.'),
        },
      ]}
      getValueProps={(valueProps) => ({
        value: valueProps?.map((item: { id: string }) => item.id),
      })}
      normalize={
        normalize ||
        ((v) => {
          if (Array.isArray(v)) {
            const locationIdSet = [
              ...new Set(
                v.flatMap((item) =>
                  findAllChildren(item, options as LocationIdType[], v),
                ),
              ),
            ];

            const formattedLocations = options
              ?.filter((item) => locationIdSet.includes(item?.id || ''))
              .map((location) => ({
                type: location.locationTypeName,
                name: location.longDisplayName,
                id: location.id,
              }));

            return formattedLocations;
          }
          return v;
        })
      }
    >
      {loading ? (
        <Skeleton.Input block />
      ) : (
        <TreeSelect
          v2
          treeData={options}
          treeDataSimpleMode
          treeCheckable
          searchValue={searchValue}
          key="id"
          virtual={false} // set this to false until Ant fixes the issue with the spacing on large list of locations
          onSearch={(value) => setSearchValue(value)}
          filterTreeNode={filterTreeNode}
          showCheckedStrategy="SHOW_PARENT"
          treeDefaultExpandedKeys={[rootKey]}
          placeholder={t('projects:Select...')}
          dropdownRender={(menu) =>
            dropdownRender({
              menu,
              searchValue,
              form,
              options: options as LocationOption[],
              isJob,
            })
          }
        />
      )}
    </FormItem>
  );
}
