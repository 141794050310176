import React, { CSSProperties, PropsWithChildren, useEffect, useState } from 'react';
import {
  COLORS,
  Card,
  Collapse,
  ConfigProvider,
  DescriptionText,
  Descriptions,
  FONTS,
  Flex,
  RADIUS,
  SPACING,
  Space,
  Tag,
  ThemeConfig,
  Typography,
  DescriptionsProps,
  Icon,
} from '@optii-solutions/ui-library';
import { Leaving, Person } from 'images/svg/reservationDetails/reservations';
import { useQuery } from '@apollo/client';
import { GET_ROOM_SUMMARY } from 'queries';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { ReservationDetail, StatusType } from './types';

const FEATURE_FLAG = process.env.NODE_ENV === 'production'; // ADD Flag to Test once it's visible.

const RESERVATION_DETAILS_THEME: ThemeConfig = {
  components: {
    Collapse: {
      headerBg: COLORS.neutral[1],
      borderRadiusLG: RADIUS.RADIUS_DEFAULT,
      colorBorder: COLORS.neutral[5],
    },
    Typography: {
      colorTextHeading: COLORS.neutral[8],
      colorText: COLORS.neutral[8],
      fontSizeHeading5: FONTS.h5.size,
    },
    Card: {
      paddingLG: SPACING.NONE,
      borderRadiusLG: RADIUS.RADIUS_DEFAULT,
      colorBorderSecondary: COLORS.neutral[5],
      headerHeight: 46,
      headerFontSize: FONTS.h5.size,
      headerFontSizeSM: FONTS.h5.size,
    },
    Tag: {
      defaultColor: COLORS.neutral[8],
      defaultBg: COLORS.neutral[3],
      colorBorder: COLORS.neutral[5],
      fontSizeSM: FONTS.small.size,
    },
  },
};

const TEXT_THEME: ThemeConfig = {
  token: {
    fontSize: FONTS.small.size,
  },
  components: {
    Typography: {
      colorText: COLORS.neutral[7],
    },
  },
};

type ReservationDetailProps = {
  reservations: ReservationDetail[];
  loading?: boolean;
};

type HeaderProps = {
  displayName: string;
  reservationName: string;
  statusLabel?: StatusType;
  dateRange: {
    arrivalDate: string;
    departureDate: string;
  };
  onClick: (key: string) => void;
  key: string;
};

type InformationProps = {
  guestNumber: string;
  specialCodes?: string;
  statusLabel?: StatusType;
  eta?: string;
  etd?: string;
  groupId?: string;
  traces?: string;
  guestPreferences?: string;
  pmsNotes?: string;
  vipStatus?: string;
  membershipLevel?: string;
  country?: string;
};

function Text(props: PropsWithChildren) {
  const { children } = props;

  return (
    <ConfigProvider theme={TEXT_THEME}>
      <Typography.Text
        style={{
          letterSpacing: FONTS.small.letterSpacing,
        }}
      >
        {children}
      </Typography.Text>
    </ConfigProvider>
  );
}

const DescriptionItems = (
  guestNumber: string,
  country?: string,
  specialCodes?: string,
  groupId?: string,
  traces?: string,
  guestPreferences?: string,
  pmsNotes?: string,
): DescriptionsProps['items'] => {
  const itemGroup = [
    {
      key:'groupId',
      span: 1,
      label: <DescriptionText type="label">Group ID</DescriptionText>,
      children: <DescriptionText type="text">{groupId || 'None'}</DescriptionText>,
    },
    {
      key: 'guest',
      label: <DescriptionText type="label">Number of Guests</DescriptionText>,
      children: <DescriptionText type="text">{guestNumber}</DescriptionText>,
    },
  ];

  if (country) {
    itemGroup.push({
      key: 'country',
      label: <DescriptionText type="label">Country</DescriptionText>,
      children: <DescriptionText type="text">{`${country || 'None'}`}</DescriptionText>,
    });
  }

  itemGroup.push({
    key: 'specialCodes',
    span: 3,
    label: <DescriptionText type="label">Specials</DescriptionText>,
    children: <Typography.Paragraph style={{ maxWidth: '100%' }}>{`${specialCodes || 'None'}`}</Typography.Paragraph>,
  });

  if (traces) {
    itemGroup.push({
      key: 'traces',
      span: 3,
      label: <DescriptionText type="label">Traces</DescriptionText>,
      children: (
        <Typography.Paragraph
          style={{ maxWidth: '100%' }}
          ellipsis={{
            rows: 1,
            expandable: true,
            symbol: 'read more',
          }}
        >
          {traces}
        </Typography.Paragraph>
      ),
    });
  }

  if (guestPreferences) {
    itemGroup.push({
      key: 'guestPreferences',
      span: 3,
      label: <DescriptionText type="label">Guest Preferences</DescriptionText>,
      children: (
        <Typography.Paragraph color='#595959' style={{ maxWidth: '100%' }}>
          {guestPreferences}
        </Typography.Paragraph>
      ),
    });
  }

  if (pmsNotes) {
    itemGroup.push({
      key: 'pmsNotes',
      span: 3,
      label: <DescriptionText type="label">PMS Notes</DescriptionText>,
      children:(
        <Typography.Paragraph
          style={{ maxWidth: '100%' }}
          ellipsis={{
            rows: 1,
            expandable: true,
            symbol: 'read more',
          }}
        >
          {pmsNotes}
        </Typography.Paragraph>
      ),
    });
  }
  
  return (itemGroup);
};

function Information(props: InformationProps) {
  const {
    guestNumber,
    specialCodes,
    statusLabel,
    eta,
    etd,
    groupId,
    traces,
    guestPreferences,
    pmsNotes,
    vipStatus,
    membershipLevel,
    country,
  } = props;

  const style: { content: CSSProperties } = {
    content: {
      paddingBottom: SPACING.SIZE_MS,
      maxWidth: '100%',
    },
  };
  const containsLeftSide = eta || etd || vipStatus || membershipLevel;

  return (
    <>
      <Flex
        align="center"
        justify={containsLeftSide ? 'space-between' : 'end'}
        style={{
          marginBottom: SPACING.SIZE_MD,
        }}
      >
        <Flex
          align="center"
          justify="start"
        >
          { eta && <Tag color="cyan">{eta}</Tag>}
          { etd && <Tag color="magenta">{etd}</Tag>}
          { vipStatus && (<Tag color="orange">{vipStatus}</Tag>) }
          { membershipLevel && (<Tag color="geekblue">Membership Level</Tag>) }
        </Flex>

        <Tag color="default">{statusLabel}</Tag>
      </Flex>
      <Descriptions
        colon={false}
        column={3}
        contentStyle={style.content}
        layout="vertical"
        items={DescriptionItems(
          guestNumber,
          country,
          specialCodes,
          groupId,
          traces,
          guestPreferences,
          pmsNotes,
        )}
      />
    </>
  );
}

function Header(props: HeaderProps) {
  const { 
    displayName,
    reservationName, 
    statusLabel, 
    dateRange,
    onClick,
    key,
  } = props;

  return (
    <Flex 
      align="center" 
      justify="space-between" 
      wrap="wrap" 
      onClick={() => onClick(key)}
    >
      <Flex align="center" gap={12}>
        <Icon
          component={
            statusLabel === StatusType.Arrival ? Leaving : Person
          }
        />
        <Space>
          <Typography.Text> {reservationName} </Typography.Text>

          <Text> #{displayName} </Text>
        </Space>
      </Flex>
      <Text>
        {dayjs(dateRange.arrivalDate).format('ddd ll')} -{' '}
        {dayjs(dateRange.departureDate).format('ddd ll')}
      </Text>
    </Flex>
  );
}

export function ReservationDetails( props: ReservationDetailProps ) {
  const { reservations, loading = false } = props;

  const { t } = useTranslation(['floorplan']);

  const [activeKeys, setActiveKeys] = useState<string[]>([]);


  const handleCollapse = (key:string) => {
    const keyIndex = activeKeys.findIndex((active) => key === active);
    if (keyIndex >= 0) {
      const newActiveKeys = [...activeKeys];
      newActiveKeys.splice(keyIndex, 1);
      setActiveKeys(newActiveKeys);
    } else {
      setActiveKeys([...activeKeys, key]);
    }
  };

  const items = reservations.map(
    (
      {
        externalReservationId,
        displayName,
        guests,
        arrivalDate,
        departureDate,
        noOfAdults,
        noOfChildren,
        estimatedArrivalTime,
        estimatedDepartureTime,
        statusLabel,
        preferences,
        notes,
        attributes,
      },
      index,
      array,
    ) => {
      const primaryGuest = guests.find(guest => guest.type === 'Primary');
      const reservationName = primaryGuest?.firstName && primaryGuest?.lastName
        ? `${primaryGuest.title || ''} ${primaryGuest.firstName} ${primaryGuest.lastName}` 
        : displayName;
      const membershipLevel = primaryGuest?.preferences?.find(preference => preference.type === 'MembershipLevel')?.value;
      const country = primaryGuest?.country;

      const pmsNotes = notes?.reduce((accumulator, note, index) => 
        index === 0 ? accumulator.concat(note.note) : accumulator.concat(`, ${note.note}`)
      , '');

      const vipStatus = preferences?.filter(
        preference => preference.type === 'VipCode')
      .reduce((accumulator, preference, index) => 
        accumulator.concat(index === 0 ? preference.value : `, ${preference.value}`), '');

      const specialCodes = preferences?.filter(
        preference => preference.type === 'Special')
      .reduce((accumulator, preference, index) => 
        accumulator.concat(index === 0 ? preference.value : `, ${preference.value}`), '');
      
      const guestPreferences = preferences?.filter(
        preference => preference.type !== 'VipCode' && preference.type !== 'Special')
      .reduce((accumulator, preference, index) => 
        accumulator.concat(index === 0 ? preference.value : `, ${preference.value}`), '');

      const groupId = attributes?.GroupCode;
      const traces = attributes?.Traces;

      const eta = estimatedArrivalTime && t('floorplan:ETA {{estimatedArrivalTime}}', {
        estimatedArrivalTime,
      });

      const etd = estimatedDepartureTime && t('floorplan:ETD {{estimatedDepartureTime}}', {
        estimatedDepartureTime,
      });
      return {
        label: Header({
          displayName,
          statusLabel,
          reservationName,
          dateRange: { arrivalDate, departureDate },
          onClick: handleCollapse,
          key: externalReservationId,
        }),
        key: externalReservationId,
        children: Information({
          guestNumber: t('floorplan:{{adults}} Adults, {{children}} Children', {
            adults: noOfAdults,
            children: noOfChildren,
          }),
          specialCodes,
          eta,
          etd,
          statusLabel,
          groupId,
          traces,
          guestPreferences,
          pmsNotes,
          vipStatus,
          membershipLevel,
          country,
        }),
        style: index + 1 === array.length ? { border: RADIUS.NONE } : undefined,
      };
    },
  );

  useEffect(() => {
    setActiveKeys(items.map((item) => item.key));
  }, [reservations]);

  return (
    <ConfigProvider theme={RESERVATION_DETAILS_THEME}>
      <Card
        title={
          <Typography.Title
            level={5}
            style={{
              paddingLeft: SPACING.SIZE_MD,
              marginTop: SPACING.NONE,
              letterSpacing: FONTS.h5.letterSpacing,
            }}
          >
            {!loading
              ? t('floorplan:Reservation Details')
              : 'LOADING RESERVATIONS...'}
            {/* TODO:Refactor ths into Skeleton /n */}
          </Typography.Title>
        }
      >
        {items.length === 0 ? (
          <Typography.Paragraph
            style={{
              marginTop: 12,
              color: 'lightgray',
              display: 'flex',
              minWidth: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            {t('floorplan: No Reservations')}
          </Typography.Paragraph>
        ) : (
          <Collapse
            activeKey={activeKeys}
            items={items}
            ghost={false}
            style={{
              border: 'none',
              marginTop: 1,
            }}
          />  
        )}
      </Card>
    </ConfigProvider>
  );
}
