import { DownOutlined } from '@ant-design/icons';
import { isPlatform } from '@ionic/react';
import { TreeSelect } from '@optii-solutions/ui-library';
import { useMemo } from 'react';
import { LOCATION_TYPES_NAMES } from 'utils/constants';

type LocationOption = {
  childCount: number;
  displayName: string;
  id: string;
  key: string;
  locationTypeCode: string;
  locationTypeId: string;
  locationTypeName: string;
  longDisplayName: string;
  name: string;
  pId: number;
  parentName: string;
  roomType: string;
  roomTypeId: string;
  roomTypeName: string;
  shortDisplayName: string;
  title: string;
  value: string;
  __typename: string;
};

type AssetFormTreeSelectProps = {
  onChange?: (locations: string[]) => void;
  onBlur?: () => void;
  value?: string[] | string;
  locations: LocationOption[];
  loadingMinimalLocations: boolean;
  disabled: boolean;
  editAsset: boolean;
  placeholder: string;
  onChangeLocations: (locations: LocationOption[]) => void;
};

export function AssetFormTreeSelect({
  onBlur,
  onChange,
  value,
  loadingMinimalLocations,
  locations,
  disabled,
  editAsset,
  placeholder,
  onChangeLocations,
}: AssetFormTreeSelectProps) {
  const selectedLocations = useMemo(() => {
    if (Array.isArray(locations) && value) {
      if (Array.isArray(value))
        return locations.filter((location) => value?.includes(location.id));
      return locations.filter((location) => value === location.id);
    }

    return [];
  }, [value, locations]);

  const defaultExpandedKeys = locations.find(
    (item) => item.locationTypeName === LOCATION_TYPES_NAMES.property,
  )?.id;

  return (
    <TreeSelect
      treeData={locations}
      suffixIcon={<DownOutlined style={{ pointerEvents: 'none' }} />}
      size="large"
      notFoundContent
      multiple={!editAsset}
      onBlur={onBlur}
      loading={loadingMinimalLocations}
      placeholder={placeholder}
      placement={isPlatform('capacitor') ? 'topLeft' : undefined}
      setValue={(items) => {
        const arrayItems = Array.isArray(items) ? items : [items];
        if (typeof onChange === 'function')
          onChange((arrayItems as LocationOption[]).map((item) => item.id));
        onChangeLocations(arrayItems as LocationOption[]);
      }}
      treeDefaultExpandedKeys={defaultExpandedKeys ? [defaultExpandedKeys] : []}
      value={selectedLocations}
      disabled={disabled}
    />
  );
}
