import { CSSProperties } from 'react';

export type SelectOption = {
  label: string;
  value: string | number;
  style?: CSSProperties;
};

export enum Priorities {
  'highest' = 'highest',
  'high' = 'high',
  'medium' = 'medium',
  'low' = 'low',
}

export enum ServiceActions {
  'noAction' = 'noAction',
  'clean' = 'clean',
  'deliver' = 'deliver',
  'remove' = 'remove',
  'repair' = 'repair',
  'replace' = 'replace',
  'inspect' = 'inspect',
  'install' = 'install',
  'perform' = 'perform',
  'test' = 'test',
}

export enum HousekeepingActions {
  'departure' = 'departure',
  'stayover' = 'stayover',
  'inspection' = 'inspection',
  'check' = 'check',
  'touchup' = 'touchup',
  'turndown' = 'turndown',
  'correction' = 'correction',
}

export enum Statuses {
  'not_started' = 'not_started',
  'in_progress' = 'in_progress',
  'completed' = 'completed',
  'cancelled' = 'cancelled',
  'on_hold' = 'on_hold',
}
