import { ReactElement } from 'react';

type ToolbarRenderInfo = {
  transform: { scale: number };
  actions: {
    onFlipY: () => void;
    onFlipX: () => void;
    onRotateLeft: () => void;
    onRotateRight: () => void;
    onZoomOut: () => void;
    onZoomIn: () => void;
    onReset: () => void;
  };

  current: number;
};

export type ToolbarRender = {
  originalNode: ReactElement;
  info: ToolbarRenderInfo;
};

export const VIDEO_EXTENSIONS = ['.mp4', '.avi', '.mov', '.webm', 'ogg'];
export const IMAGE_EXTENSIONS = [
  '.jpg',
  '.png',
  '.jpeg',
  '.gif',
  '.svg',
  '.JPG',
  '.PNG',
  '.JPEG',
  '.GIF',
  '.SVG',
];

export const isFileExtensionImageOrVideo = (name: string) => {
  const FILE_NAME_MATCH_REGEX =
    name.match(/\.\w{3,4}($)/) && name.match(/\.\w{3,4}($)/)?.[0];

  if (FILE_NAME_MATCH_REGEX) {
    const isVideo = VIDEO_EXTENSIONS.includes(
      FILE_NAME_MATCH_REGEX.toLowerCase(),
    );

    const isImage = IMAGE_EXTENSIONS.includes(
      FILE_NAME_MATCH_REGEX.toLowerCase(),
    );

    return {
      isVideo,
      isImage,
    };
  }

  return {
    isVideo: false,
    isImage: false,
  };
};

export const generateBase64ImageFromVideo = (url: string) =>
  new Promise<string>((resolve) => {
    const video = document.createElement('video');
    video.src = url;
    video.crossOrigin = 'anonymous';
    video.playsInline = true;
    video.onloadedmetadata = () => {
      const canvas = document.createElement('canvas');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const context = canvas.getContext('2d');

      video.currentTime = 0;
      video.onseeked = () => {
        context?.drawImage(video, 0, 0, canvas.width, canvas.height);

        const base64Image = canvas.toDataURL('image/png');
        resolve(base64Image);
      };
    };
  });
