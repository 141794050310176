import i18n from './i18next';

export const PERMISSION_NAMES = {
  access_checklists: {
    displayName: i18n.t('rolesAndPermissions:Access Checklist'),
  },
  sch_view: {
    displayName: i18n.t('rolesAndPermissions:Access Schedule'),
  },
};

export const PERMISSIONS = {
  schedule: {
    any: 'sch_*',
    view: 'sch_view',
  },
  jobs: {
    any: 'job_*',
    view: 'job_view',
    all: 'job_all_view', // kanban view
    autoassign: 'job_autoassign_enable',
    team_member: 'job_team_member_view', // team member view
    autocreate: 'job_item_autocreate_enable',
  },
  house_keeping: {
    any: 'house_keeping_*',
    view: 'housekeeping_jobs_view_job',
    add_edit: 'housekeeping_jobs_add_edit_job',
    delete: 'housekeeping_jobs_delete_job',
  },
  communications: {
    any: 'com_*',
    view: 'com_view',
    chatAssist: 'com_chat_assist',
  },
  reports: {
    any: 'report_*',
    jobActivityView: 'report_job_activity_view',
    teamMemberActivityView: 'report_team_member_activity_view',
    projectCycleSummaryView: 'report_project_cycle_summary_view',
    abovePropertyReportAccess: 'report_above_property_access',
    reportJobActivityExport: 'report_job_activity_export',
    reportJobAssetExport: 'report_job_asset_export',
    reportChecklistDetailExport: 'report_checklist_detail_export',
    reportPreventiveMaintenanceProgramAccess: 'report_pm_program_access',
    reportDailySheetExport: 'report_daily_sheet_export',
    accessHousekeepingHistoryReport: 'access_housekeeping_history_report',
  },
  engineering: {
    any: 'eng_*',
    accessProjects: 'eng_projects_view',
  },
  settings: {
    any: 'set_*',
    users: {
      any: 'set_usr*',
      view: 'set_usr_view',
      pii: 'set_usr_pii_view',
    },
    roles: {
      any: 'set_rol*',
      view: 'set_rol_view',
    },
    orgs: {
      any: 'set_org*',
      view: 'set_org_view',
    },
    empTypes: {
      any: 'set_emp*',
      view: 'set_emp_view',
    },
    checklists: {
      any: 'checklists*',
      view: 'access_checklists',
    },
    departments: {
      any: 'set_dpt*',
      view: 'set_dpt_view',
    },
    jobs: {
      any: 'view_job*',
      view: 'view_job_settings',
    },
    floorplan: {
      any: 'set_floorplan*',
      view: 'set_floorplan_view',
      locationDetailView: 'location_detail_view',
    },
    jobItems: {
      any: 'view_job*',
      view: 'view_job_items',
    },
    notifications: {
      any: 'view_notifications*',
      view: 'view_notifications',
    },
    repeatingJobs: {
      any: 'repeating_job*',
      view: 'repeating_job_view',
    },
    onShiftOption: 'on_shift_option_enable',
    assets: {
      any: 'set_asset*',
      view: 'set_asset_view',
    },
    rooms: {
      any: 'room_drawer*',
    },
  },
};

export const PRODUCT_ACCESS = {
  configuration: 'configuration',
  communication: 'communication',
  house_keeping: 'house_keeping',
  service_delivery: 'service_delivery',
  schedule: 'schedule',
  user_profile: 'user_profile',
  jobs: 'jobs',
  reports: 'reports',
  home_dashboard: 'home_dashboard',
  settings: 'settings',
  engineering: 'engineering',
  translation: 'translation',
  smart_operations: 'smart_operations',
  pms_Data: 'pms_data',
};
