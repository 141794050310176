import ReactGA from 'react-ga4';

import { context } from './config';

if (context.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID) {
  ReactGA.initialize(context.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID, {
    testMode: process.env.NODE_ENV === 'test',
  });
}

export default ReactGA;
