import React, { useEffect, useState } from 'react';
import Main from 'components/Main';
import { ThemeProvider } from 'styled-components';
import theme from 'theme';
import {
  ApolloProvider as Provider,
  ApolloProvider as OfficialProvider,
} from '@apollo/client';
import { BrowserRouter as Router, Route, useHistory } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import {
  UserAccessContext,
  EmploymentContext,
  PropertyContext,
  useSnack,
  PropertyDetailsContext,
} from '@optii/shared';
import OnlineUsersContext from 'contexts/OnlineUsersContext';
import WorkingIndicatorContext from 'contexts/WorkingIndicatorContext';
import AppUrlListener from 'AppUrlListener';
import { Auth } from '@optii/shared/components/organisms/Authentication/AuthService';
import GoogleAnalyticsClient from '@optii/shared/utils/GoogleAnalyticsClient';
import ErrorBoundary from 'components/shared/ErrorBoundary';
import {
  ConfigProvider,
  defaultTheme,
} from '@optii-solutions/ui23-antd-components';
import {
  DEFAULT_THEME,
  ConfigProvider as UIProvider,
  App as AntApp,
} from '@optii/ui-library'; // TODO: remove all ui-23-antd-components implementations.
import { KeyboardPlugin } from 'plugins/KeyboardPlugin';
import { Capacitor } from '@capacitor/core';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import GraphClient from './GraphClient';

import 'dayjs/locale/bg';
import 'dayjs/locale/bs';
import 'dayjs/locale/de';
import 'dayjs/locale/el';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/en-au';
import 'dayjs/locale/en-nz';
import 'dayjs/locale/es';
import 'dayjs/locale/es-mx';
import 'dayjs/locale/et';
import 'dayjs/locale/fr';
import 'dayjs/locale/it';
import 'dayjs/locale/ja';
import 'dayjs/locale/pl';
import 'dayjs/locale/pt-br';
import 'dayjs/locale/ro';
import 'dayjs/locale/ru';
import 'dayjs/locale/sq';
import 'dayjs/locale/tl-ph';
import 'dayjs/locale/tr';
import 'dayjs/locale/uk';
import 'dayjs/locale/zh-cn';
import 'dayjs/locale/zh-tw';

dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

const { Keyboard } = KeyboardPlugin;

if (Capacitor.isPluginAvailable('Keyboard')) {
  Keyboard.addListener('keyboardWillShow', ({ keyboardHeight }) => {
    if (
      document.activeElement.getBoundingClientRect().y >
      document.documentElement.clientHeight / 2 + 25
    )
      document.body.style.transform = `translateY(-${keyboardHeight + 10}px)`;
  });

  Keyboard.addListener('keyboardWillHide', () => {
    // Reset the position of your content when the keyboard is hidden
    document.body.style.transform = 'translateY(0)';
  });
}

// Pass along auth to graphclient instances
const App = function () {
  const [graphClient, setGraphClient] = useState(false);
  const history = useHistory();
  const { setSnack, Snack } = useSnack();

  useEffect(
    (_) => {
      history.listen((location) => {
        GoogleAnalyticsClient.set({ page: location }); // Update the user's current page
        GoogleAnalyticsClient.send({
          hitType: 'pageview',
          page: location.pathname,
          title: location.pathname,
        }); // Record a pageview for the given page
      });
    },
    [history],
  );
  // Only setup the graph client once
  useEffect((_) => {
    setGraphClient(GraphClient(Auth.Instance));
  }, []);

  if (!graphClient) {
    return null;
  }

  return (
    <QueryParamProvider ReactRouterRoute={Route}>
      <OfficialProvider client={graphClient}>
        <Provider client={graphClient}>
          <ThemeProvider theme={theme}>
            <UserAccessContext.Provider>
              <PropertyContext.Provider>
                <PropertyDetailsContext.Provider>
                  <EmploymentContext.Provider>
                    <WorkingIndicatorContext.Provider>
                      <OnlineUsersContext.Provider>
                        <ConfigProvider theme={defaultTheme}>
                          <UIProvider theme={DEFAULT_THEME}>
                            <AntApp component={false}>
                              <AppUrlListener />
                              <ErrorBoundary>
                                <Main {...{ globalSnack: setSnack }} />
                                <Snack />
                              </ErrorBoundary>
                            </AntApp>
                          </UIProvider>
                        </ConfigProvider>
                      </OnlineUsersContext.Provider>
                    </WorkingIndicatorContext.Provider>
                  </EmploymentContext.Provider>
                </PropertyDetailsContext.Provider>
              </PropertyContext.Provider>
            </UserAccessContext.Provider>
          </ThemeProvider>
        </Provider>
      </OfficialProvider>
    </QueryParamProvider>
  );
};

function RouterContainer(_) {
  return (
    <Router>
      <App />
    </Router>
  );
}
export default RouterContainer;
