import dayjs from 'dayjs';
import moment, { Moment } from 'moment';
import { RRule, Weekday } from 'rrule';

import { RoomSummary } from '@optii/jobs/api/repeatingJobs';
import { createMonthlyCadenceRule } from '@optii/jobs/formatters/repeatingRules';
import { Maybe } from '@optii/jobs/api/jobs';
import {
  DAYS_OF_WEEK,
  JOB_ADVANCED_STATUSES,
  JOB_METADATA,
  JOB_REPEAT_CYCLE,
  JOB_REPEAT_CYCLE_TYPE,
  JOB_STATUSES,
  MONTHS,
  ORDINAL_SUFFIX,
} from './JobForm.constants';
import type {
  TDaysOfWeek,
  TEditJobDataLocation,
  TGetCycle,
  TGetDailyRepeatingJobCycle,
  TGetEditJobAssignee,
  TGetEditJobLocations,
  TGetEditRepeatingJobCycle,
  TGetFormattedJobDepartment,
  TGetFormattedJobRole,
  TGetJobLocations,
  TGetMonthlyRepeatingJobCycle,
  TGetRepeatingJobCycle,
  TGetRepeatingJobLocations,
  TGetSTatusesFromValues,
  TGetWeeklyRepeatingJobCycle,
  TGetYearlyRepeatingJobCycle,
  TJobAdvancedStatuses,
  TJobDataAsset,
  TJobDataLocation,
  TJobFormDropdownCustomHeightOnFocus,
  TJobFormGetHelper,
  TJobFormMode,
  TJobInputLocations,
  TJobStatuses,
  TJobStatusesValues,
  TRepeatingJobCycleDisplayName,
  TRepeatingJobCycleKeys,
} from './JobForm.types';

export function getDaysPositionInMonth(date: Moment) {
  // destructure original date

  const dayNumber = date.date();
  const weekDay = date.day();
  const monthDay = date.month();
  const year = date.year();

  // grab the first occurrence of the selected week day
  const firstOccurrence = moment()
    .set('month', monthDay)
    .set('year', year)
    .startOf('month')
    .day(weekDay);

  const occurrences = [];
  const occurrencePosition = ['first', 'second', 'third', 'fourth', 'last'];
  // Map and store all occurrences of the weekday on the month of the selected date.
  if (firstOccurrence.date() > 7) firstOccurrence.add(7, 'd');
  const month = firstOccurrence.month();
  while (month === firstOccurrence.month()) {
    const instantiateDate = firstOccurrence.date();
    occurrences.push(instantiateDate);
    firstOccurrence.add(7, 'd');
  }
  // Remove the "fourth" option if the length of the arrays differ.
  if (occurrencePosition.length !== occurrences.length) {
    occurrencePosition.splice(occurrencePosition.indexOf('fourth'), 1);
  }
  // Reduce and transform to a object in which the key is the occurrence (e.g first) and value being the date day.
  const formattedOccurrences = occurrences.reduce(
    (a, v, i) => ({ ...a, [occurrencePosition[i]]: v }),
    {},
  );

  // Return the key based on the selected start Date day.
  return Object.keys(formattedOccurrences).find(
    (key) =>
      formattedOccurrences[key as keyof typeof formattedOccurrences] ===
      dayNumber,
  );
}

export function getRepeatingJobOrdinalSuffixString({ day }: { day: string }) {
  const ordinalSuffixIndex =
    Number(day) > 3
      ? ORDINAL_SUFFIX.length - 1
      : (ORDINAL_SUFFIX[Number(day)] as keyof typeof ORDINAL_SUFFIX);
  const ordinalSuffix = ORDINAL_SUFFIX[ordinalSuffixIndex];

  return `${day}${ordinalSuffix}`;
}

export function getFormattedJobRole({
  currentRole,
  roles,
  roleHasNotBeenChanged,
  role,
}: TGetFormattedJobRole) {
  if (roleHasNotBeenChanged) {
    return roles
      .filter((_role) => _role.id === currentRole)
      .map((_role) => ({
        id: _role.id,
        name: _role.name,
        description: _role.description,
      }))[0];
  }

  if (role && role.id && role.name) {
    return {
      id: role.id,
      name: role.name,
      description: role.description,
    };
  }

  return undefined;
}

export function getItems(
  items?: { name: string; amount: number }[],
  joiner = ', ',
) {
  let out = '';
  if (!items) return out;
  for (let i = 0; i < items.length; i += 1) {
    const item = items[i];
    out += item.name;
    if (item.amount > 1) out += ` (${item.amount})`;
    if (i + 1 < items.length) out += joiner;
  }

  return out;
}

export function getFormattedJobDeparment({
  currentDeparment,
  departments,
  departmentHasNotBeenChanged,
  department,
}: TGetFormattedJobDepartment) {
  if (departmentHasNotBeenChanged) {
    return departments
      .filter((_department) => _department.id === currentDeparment)
      .map((_department) => ({
        id: _department.id,
        displayName: _department.displayName,
        departmentCode: _department.departmentCode,
      }))[0];
  }

  return {
    id: department?.id,
    displayName: department?.displayName,
    departmentCode: department?.departmentCode,
  };
}

function getYearlyRepeatingJobCycle({
  values,
  cycleDisplayName,
}: TGetYearlyRepeatingJobCycle<
  TGetRepeatingJobCycle & TGetEditRepeatingJobCycle
>) {
  const date = values.startDate;
  const day = dayjs(date).format('D');
  const month = dayjs(date).format('M');

  const cycleDisplayNameDayString = getRepeatingJobOrdinalSuffixString({
    day,
  });
  const cycleDisplayNameMonthString = `${MONTHS[Number(month) - 1]}`;

  const cycleValue = {
    FREQ: values.repeat?.toUpperCase(),
    INTERVAL: values.repeatYears,
    BYMONTH: month,
    BYMONTHDAY: day,
  };

  const cycleValueArray = Object.keys(cycleValue).map((item) => {
    const currentItem = `${item}=${
      cycleValue[item as keyof typeof cycleValue]
    }`;

    return currentItem;
  });
  const cycleValueString = [...cycleValueArray].join(';');

  const cycleDisplayNameString = `${cycleDisplayName} ${cycleDisplayNameMonthString} on the ${cycleDisplayNameDayString}`;

  return {
    displayName: cycleDisplayNameString,
    type: JOB_REPEAT_CYCLE_TYPE,
    value: cycleValueString,
  };
}

function getMonthlyRepeatingJobCycle({
  values,
}: TGetMonthlyRepeatingJobCycle<
  TGetRepeatingJobCycle & TGetEditRepeatingJobCycle
>) {
  const rule = createMonthlyCadenceRule({
    cycle: {
      interval: Number(values.repeatMonths),
      byWeekDay: values.dayOfMonth === '2',
      byMonthDay: values.dayOfMonth === '1',
    },
    startDate: moment(values.startDate.format('YYYY-MM-DD')),
  });

  return {
    displayName: rule.toText(),
    type: JOB_REPEAT_CYCLE_TYPE,
    value: rule.toString().slice(6),
  };
}

function getWeeklyRepeatingJobCycle({
  values,
  cycleDisplayName,
}: TGetWeeklyRepeatingJobCycle<
  TGetRepeatingJobCycle & TGetEditRepeatingJobCycle
>) {
  const cycleValue = {
    FREQ: values.repeat?.toUpperCase(),
    INTERVAL: values.repeatWeeks,
    BYDAY: '',
  };

  const daysOfWeek = values.daysOfWeek?.length ? [...values.daysOfWeek] : [];

  const daysOfWeekAbbr = daysOfWeek.map((item) =>
    DAYS_OF_WEEK[item as keyof TDaysOfWeek].value.substring(0, 2).toUpperCase(),
  );
  const daysOfWeekFullName = daysOfWeek.map(
    (item) => DAYS_OF_WEEK[item as keyof TDaysOfWeek].displayName,
  );

  const cycleValueArray = Object.keys(cycleValue).map((item) => {
    let currentItem = `${item}=${cycleValue[item as keyof typeof cycleValue]}`;

    if (item === 'BYDAY' && daysOfWeek?.length) {
      currentItem = `${item}=${daysOfWeekAbbr.join(',')}`;
    }

    return currentItem;
  });
  const cycleValueString = [...cycleValueArray].join(';');

  const cycleDisplayNameDayString = daysOfWeekFullName.join(', ');

  const cycleDisplayNameString = values.daysOfWeek?.length
    ? `${cycleDisplayName} on ${cycleDisplayNameDayString}`
    : cycleDisplayName;

  return {
    displayName: cycleDisplayNameString,
    type: JOB_REPEAT_CYCLE_TYPE,
    value: cycleValueString,
  };
}

function getDailyRepeatingJobCycle({
  values,
  cycleDisplayName,
}: TGetDailyRepeatingJobCycle<
  TGetRepeatingJobCycle & TGetEditRepeatingJobCycle
>) {
  const firstDueTimeMinute = dayjs(values.firstDueTime).format('m');

  const cycleValue = {
    FREQ: values.repeat?.toUpperCase(),
    INTERVAL: '1',
    BYHOUR: '',
    BYMINUTE: firstDueTimeMinute,
  };

  const firstDueTimeHour = Number(dayjs(values.firstDueTime)?.format('H'));

  const currentTotalTime =
    Number(values.timesPerDay) * Number(values.repeatHour);
  const hoursLength = firstDueTimeHour ? 24 : currentTotalTime;

  const hours = Array.from(
    { length: (hoursLength - firstDueTimeHour) / Number(values.repeatHour) },
    (value, index) => firstDueTimeHour + index * Number(values.repeatHour),
  );

  const slicenHours = [...hours].slice(0, Number(values.timesPerDay));

  const cycleValueArray = Object.keys(cycleValue).map((item) => {
    let currentItem = `${item}=${cycleValue[item as keyof typeof cycleValue]}`;

    if (item === 'BYHOUR' && values.multiple && values.repeatHour) {
      currentItem = `${item}=${slicenHours.join(',')}`;
    }

    return currentItem;
  });
  const cycleValueString = values.multiple
    ? cycleValueArray.join(';')
    : [...cycleValueArray].slice(0, 2).join(';');

  const cycleDisplayNameHours = [...slicenHours].slice(
    0,
    slicenHours.length ? slicenHours.length - 1 : 0,
  );
  const cycleDisplayNameHoursString =
    slicenHours?.length > 1
      ? `${cycleDisplayNameHours.join(', ')} and ${
          slicenHours[slicenHours.length ? slicenHours.length - 1 : 0]
        }`
      : cycleDisplayNameHours[1];
  const cycleDisplayNameString = values.multiple
    ? `${cycleDisplayName} at ${cycleDisplayNameHoursString}`
    : cycleDisplayName;

  return {
    displayName: cycleDisplayNameString,
    type: JOB_REPEAT_CYCLE_TYPE,
    value: cycleValueString,
  };
}

export function getRepeatingJobCycle({
  values,
}: TGetRepeatingJobCycle & TGetEditRepeatingJobCycle) {
  const cycleDisplayName: TRepeatingJobCycleDisplayName | string =
    values?.repeat
      ? JOB_REPEAT_CYCLE[values.repeat as TRepeatingJobCycleKeys].displayName
          .replace(
            /\{\{week\}\}/g,
            `${values.repeatWeeks} ${
              Number(values.repeatWeeks) === 1 ? 'week' : 'weeks'
            }`,
          )
          .replace(
            /\{\{month\}\}/g,
            `${values.repeatMonths} ${
              Number(values.repeatMonths) === 1 ? 'month' : 'months'
            }`,
          )
          .replace(
            /\{\{year\}\}/g,
            `${values.repeatYears} ${
              Number(values.repeatYears) === 1 ? 'year' : 'years'
            }`,
          )
      : '';

  const getCycle: TGetCycle = {
    daily: getDailyRepeatingJobCycle,
    weekly: getWeeklyRepeatingJobCycle,
    monthly: getMonthlyRepeatingJobCycle,
    yearly: getYearlyRepeatingJobCycle,
  };

  return getCycle[values?.repeat as keyof TGetCycle]({
    values,
    cycleDisplayName,
  });
}

export function getRepeatingJobLocations({
  currentLocations,
  currentAssetLocations,
  locations,
  isAssetTypeLocation,
}: TGetRepeatingJobLocations) {
  if (isAssetTypeLocation && Array.isArray(currentAssetLocations)) {
    const selectedLocations =
      currentAssetLocations?.map((locationItem: TJobDataAsset) => {
        const { locationId: id } = locationItem;

        return {
          id,
        };
      }) || ([] as TJobInputLocations[]);

    return selectedLocations;
  }

  return (
    (currentLocations &&
      typeof currentLocations === 'object' &&
      currentLocations.length &&
      currentLocations.map((locationItem) => {
        const currentLocation =
          locations?.find((location) => location.id === locationItem) ||
          ({} as TEditJobDataLocation);

        return {
          id: currentLocation.id,
        };
      })) ||
    ([] as TJobInputLocations[])
  );
}

export function getEditRepeatingJobLocations({
  currentLocations,
  currentAssetLocations,
  locations,
  isAssetTypeLocation,
}: TGetRepeatingJobLocations) {
  if (isAssetTypeLocation && Array.isArray(currentAssetLocations)) {
    const selectedLocations =
      currentAssetLocations?.map((locationItem: TJobDataAsset) => {
        const { locationId: id, locationTitle: type } = locationItem;

        return {
          id,
          name: id,
          type,
        };
      }) || ([] as TJobInputLocations[]);

    return selectedLocations;
  }

  return (
    (currentLocations &&
      typeof currentLocations === 'object' &&
      currentLocations.length &&
      currentLocations.map((locationItem) => {
        const currentLocation =
          locations?.find((location) => location.id === locationItem) ||
          ({} as TEditJobDataLocation);

        return {
          id: currentLocation.id,
          name: currentLocation.name,
          type: currentLocation.locationTypeName,
        };
      })) ||
    ([] as TJobInputLocations[])
  );
}

export function getJobLocations({
  currentLocations,
  currentAssetLocations,
  locations,
  isAssetTypeLocation,
}: TGetJobLocations) {
  if (isAssetTypeLocation) {
    const selectedLocations =
      (currentAssetLocations?.length &&
        currentAssetLocations?.map((locationItem: TJobDataAsset) => {
          const { locationId: id, locationTitle: type } = locationItem;
          return {
            id,
            name: id,
            type,
          };
        })) ||
      ([] as TJobInputLocations[]);

    return selectedLocations;
  }

  const selectedLocations =
    currentLocations?.map((locationItem: TJobDataLocation) => {
      const currentLocation =
        locations?.find((location) => location.id === locationItem.id) ||
        ({} as TJobDataLocation);

      return {
        id: currentLocation.id,
        name: currentLocation.name,
        type: currentLocation.locationTypeName,
      };
    }) || ([] as TJobInputLocations[]);

  return selectedLocations;
}

export function getRepeatingJobDayPosition({
  t,
  dayOfWeek,
}: {
  t: (
    a: string,
    b: {
      dayOfWeek: string | number;
    },
  ) => string;
  dayOfWeek: string;
}) {
  return {
    first: t('projects:Monthly on the first {{dayOfWeek}}', { dayOfWeek }),
    second: t('projects:Monthly on the second {{dayOfWeek}}', {
      dayOfWeek,
    }),
    third: t('projects:Monthly on the third {{dayOfWeek}}', {
      dayOfWeek,
    }),
    fourth: t('projects:Monthly on the fourth {{dayOfWeek}}', {
      dayOfWeek,
    }),
    last: t('projects:Monthly on the last {{dayOfWeek}}', {
      dayOfWeek,
    }),
  };
}

export const capitalize = ([firstLetter, ...restOfWord]: string[]) =>
  firstLetter.toUpperCase() + restOfWord.join('');

export const capitalizeFirstLetter = (word: string) =>
  `${word.charAt(0).toUpperCase()}${word.slice(1)}`;

export const getActionTypeOptions = ({ t }: TJobFormGetHelper) => [
  {
    label: t('jobs:Clean'),
    value: 'clean',
  },
  {
    label: t('jobs:Deliver'),
    value: 'deliver',
  },
  {
    label: t('jobs:Remove'),
    value: 'remove',
  },
  {
    label: t('jobs:Repair'),
    value: 'repair',
  },
  {
    label: t('jobs:Test'),
    value: 'test',
  },
  {
    label: t('jobs:Replace'),
    value: 'replace',
  },
  {
    label: t('jobs:Inspect'),
    value: 'inspect',
  },
  {
    label: t('jobs:Install'),
    value: 'install',
  },
  {
    label: t('jobs:Perform'),
    value: 'perform',
  },
];

export const getHKActionTypeOptions = ({ t }: TJobFormGetHelper) => [
  {
    label: t('jobs:Departure'),
    value: 'departure',
  },
  {
    label: t('jobs:Stayover'),
    value: 'stayover',
  },
  {
    label: t('jobs:Inspection'),
    value: 'inspection',
  },
  {
    label: t('jobs:Check'),
    value: 'check',
  },
  {
    label: t('jobs:Touch-up'),
    value: 'touchup',
  },
  {
    label: t('jobs:Turndown'),
    value: 'turndown',
  },
  {
    label: t('jobs:Correction'),
    value: 'correction',
  },
];

export const getJobTypes = ({ t }: TJobFormGetHelper) => ({
  guest: {
    label: t('jobs:Guest Request'),
    value: 'guest',
  },
  internal: {
    label: t('jobs:Internal'),
    value: 'internal',
  },
  housekeeping: {
    label: t('jobs:Housekeeping'),
    value: 'housekeeping',
  },
});

export const getJobStatus = ({ t }: TJobFormGetHelper) => ({
  guest: {
    label: t('jobs:Guest Request'),
    value: 'guest',
  },
  internal: {
    label: t('jobs:Internal'),
    value: 'internal',
  },
  housekeeping: {
    label: t('jobs:Housekeeping'),
    value: 'housekeeping',
  },
});

export function getStatusFromValue({ value }: TGetSTatusesFromValues) {
  const jobStatusesValues = Object.values(JOB_STATUSES);
  const jobStatusesKeys = Object.keys(JOB_STATUSES);

  const currentJobStatusIndex = jobStatusesValues.findIndex(
    (jobStatus) => jobStatus.value === value,
  );
  const currentJobStatus = jobStatusesKeys[currentJobStatusIndex];

  const currentJobStatusValue =
    JOB_STATUSES[currentJobStatus as keyof typeof JOB_STATUSES]?.value;

  if (currentJobStatusValue) {
    return currentJobStatusValue;
  }

  const jobAdvancedStatusesValues = Object.values(JOB_ADVANCED_STATUSES);
  const jobAdvancedStatusesKeys = Object.keys(JOB_ADVANCED_STATUSES);

  const currentJobAdvancedStatusIndex = jobAdvancedStatusesValues.findIndex(
    (jobStatus) => jobStatus.value === value,
  );
  const currentJobAdvancedStatus =
    jobAdvancedStatusesKeys[currentJobAdvancedStatusIndex];

  const currentJobAdvancedStatusSameAs =
    JOB_ADVANCED_STATUSES[
      currentJobAdvancedStatus as keyof typeof JOB_ADVANCED_STATUSES
    ]?.sameAs;

  return JOB_STATUSES[
    currentJobAdvancedStatusSameAs as keyof typeof JOB_STATUSES
  ]?.value;
}

export function getEditJobAssignee({
  currentAssignee,
  assignees,
  assigneeHasNotBeenChanged,
  assignee,
}: TGetEditJobAssignee) {
  if (assigneeHasNotBeenChanged) {
    return assignees
      .filter((assigneeItem) => String(assigneeItem.id) === currentAssignee)
      .map((assigneeItem) => ({
        id: assigneeItem.id,
        userName: assigneeItem.userName,
        firstName: assigneeItem.userFirstName,
        lastName: assigneeItem.userLastName,
      }))[0];
  }

  if (assignee && assignee.id && assignee.userName) {
    return {
      id: assignee.id,
      userName: assignee.userName,
      firstName: assignee.firstName,
      lastName: assignee.lastName,
    };
  }

  return undefined;
}

export function getEditJobLocations({
  currentLocations,
  currentAssetLocations,
  locations,
  isAssetTypeLocation,
  fallbackLocations,
}: TGetEditJobLocations) {
  if (isAssetTypeLocation && Array.isArray(currentAssetLocations)) {
    const selectedLocations =
      currentAssetLocations?.map((locationItem: TJobDataAsset) => {
        const { locationId: id, locationTitle: type } = locationItem;

        return {
          id,
          name: id,
          type,
        };
      }) || ([] as TJobInputLocations[]);

    return selectedLocations;
  }

  return (
    (currentLocations &&
      typeof currentLocations === 'object' &&
      currentLocations.length &&
      currentLocations.map((locationItem: string) => {
        const isExistsCurrentLocation = locations?.find(
          (location) => location.id === locationItem,
        );
        const fallbackLocation = fallbackLocations?.find(
          (location) => location?.id === locationItem,
        );
        const currentLocation = isExistsCurrentLocation || {
          id: fallbackLocation?.id,
          name: fallbackLocation?.name,
          locationTypeName: fallbackLocation?.type,
        };

        return {
          id: currentLocation.id,
          name: currentLocation.name,
          type: currentLocation.locationTypeName,
        };
      })) ||
    ([] as TJobInputLocations[])
  );
}

export function getAdvancedStatusFromValue({ value }: TGetSTatusesFromValues) {
  const jobAdvancedStatusesValues = Object.values(JOB_ADVANCED_STATUSES);
  const jobAdvancedStatusesKeys = Object.keys(JOB_ADVANCED_STATUSES);

  const currentJobAdvancedStatusIndex = jobAdvancedStatusesValues.findIndex(
    (jobStatus) => jobStatus.value === value,
  );
  const currentJobAdvancedStatus =
    jobAdvancedStatusesKeys[currentJobAdvancedStatusIndex];

  const currentJobAdvancedStatusSameAs =
    JOB_ADVANCED_STATUSES[
      currentJobAdvancedStatus as keyof typeof JOB_ADVANCED_STATUSES
    ]?.sameAs;

  if (currentJobAdvancedStatusSameAs) {
    return currentJobAdvancedStatusSameAs;
  }

  const jobStatusesValues = Object.values(JOB_STATUSES);
  const jobStatusesKeys = Object.keys(JOB_STATUSES);

  const currentJobStatusIndex = jobStatusesValues.findIndex(
    (jobStatus) => jobStatus.value === value,
  );
  const currentJobStatus = jobStatusesKeys[currentJobStatusIndex];

  const currentJobStatusValue =
    JOB_STATUSES[currentJobStatus as keyof typeof JOB_STATUSES]?.value;

  if (currentJobStatusValue) {
    return currentJobStatusValue;
  }

  return undefined;
}

export function getDecodeDailyCadenceFromText(cycleString: string) {
  const rrule = RRule.fromString(cycleString);

  const { origOptions } = rrule;
  const decodedOptions = {
    multipleTimesPerDay: false,
    interval: 0,
    timesPerDay: 0,
    firstHour: 0,
  };

  if (
    origOptions.byhour &&
    typeof origOptions.byhour === 'object' &&
    origOptions.byhour.length
  ) {
    decodedOptions.multipleTimesPerDay = true;
    decodedOptions.interval =
      origOptions.byhour.length > 1
        ? origOptions.byhour[1] - origOptions.byhour[0]
        : 1;
    decodedOptions.timesPerDay = origOptions.byhour.length;
    decodedOptions.firstHour = origOptions?.byhour[0];
  }

  return decodedOptions;
}

export function getDecodeWeeklyCadenceFromText(cycleString: string) {
  const rule = RRule.fromString(cycleString);

  const { origOptions } = rule;
  const decodedOptions = {
    interval: origOptions.interval,
    weekDay: [],
  };

  if (
    origOptions.byweekday &&
    !Object.prototype.hasOwnProperty.call(origOptions.byweekday, 'weekday')
  ) {
    const weekDays = [
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
      'sunday',
    ];

    return {
      ...decodedOptions,
      weekDay: (origOptions?.byweekday as Weekday[]).map(
        (day) => weekDays[day.weekday],
      ),
    };
  }

  return decodedOptions;
}

export function getDecodeMonthlyCadenceFromText(cycleString: string) {
  const rule = RRule.fromString(cycleString);

  const { origOptions } = rule;
  const decodedOptions = {
    interval: origOptions.interval || 1,
    dayOfMonth: 0,
  };

  if (origOptions.bymonthday) {
    decodedOptions.dayOfMonth = 1;
  }

  if (origOptions.byweekday) {
    decodedOptions.dayOfMonth = 2;
  }

  return decodedOptions;
}

export function getDecodeYearlyCadenceFromText(cycleString: string) {
  const rule = RRule.fromString(cycleString);
  const decodedRule = rule.origOptions;

  return decodedRule;
}

type TGetRoomStatus = {
  roomsData: {
    roomsById?: RoomSummary[];
  };
};

export function getRoomStatus({ roomsData }: TGetRoomStatus) {
  const roomData = roomsData?.roomsById && roomsData?.roomsById[0];
  const status =
    roomData?.occupancyStatus &&
    roomData?.cleaningStatus &&
    `${roomData?.occupancyStatus} ${roomData?.cleaningStatus}`;

  return {
    status,
    roomTypeCode: '',
  };
}

export function JobFormDropdownCustomHeightOnFocus({
  height,
}: TJobFormDropdownCustomHeightOnFocus): void {
  const container = document
    .getElementById('job-form-drawer')
    ?.querySelector('.ant-drawer-body');

  const currentScrollY = Number(container?.scrollTop);
  const newScrollY = currentScrollY + height;
  container?.scrollTo({
    top: newScrollY,
  });
}

const isValidId = (id: string) => !!id;
const isNonEmptyString = (value: string) =>
  typeof value === 'string' && value.length > 0;
const isNonEmptyArray = (arr: Array<any>) =>
  Array.isArray(arr) && arr.length > 0;

type TGetAssigneeValidation = {
  values: {
    assignee: string & {
      id?: string;
    };
  };
  previousValues: {
    assignee: {
      id?: string;
    };
  };
};

const getAssigneeValidation = ({
  values,
  previousValues,
}: TGetAssigneeValidation) => ({
  hasPreviousAssignee: !!previousValues?.assignee?.id,
  hasCurrentAssignee:
    !!values?.assignee?.id ||
    values?.assignee === '88' ||
    isNonEmptyString(values?.assignee),
});

type TValidateJobItems = {
  values: {
    items: Array<{ id: string }>;
    assetId?: string;
  };
  FORM_OPTIONS: any;
  mode?: TJobFormMode;
  isProjectJob: boolean;
};

const validateJobItems = ({
  values,
  FORM_OPTIONS,
  mode,
  isProjectJob,
}: TValidateJobItems) => {
  const selectedItems = values.items?.filter((item) => !!item.id);
  const hasItems = selectedItems?.length === values.items?.length;

  let isAssetSelected = selectedItems
    ? FORM_OPTIONS.items?.find(
        (item: { value: string }) => item.value === selectedItems[0].id,
      )?.isAsset
    : undefined;

  if (mode === 'edit' && isProjectJob) {
    isAssetSelected = values?.assetId;
  }

  return { hasItems, isAssetSelected };
};

type TValidateJobAssets = {
  values: {
    asset: string & Array<{ id: string }>;
  };
  isAssetSelected: boolean;
};

const validateJobAssets = ({ values, isAssetSelected }: TValidateJobAssets) => {
  const selectedAssets =
    (isNonEmptyString(values.asset) && typeof values.asset === 'string') ||
    !!values.asset?.filter((item) => !!item?.id).length;

  return !!selectedAssets && !!isAssetSelected;
};

type TValidateJobLocations = {
  values: {
    location: Array<{ id: string }>;
  };
  isAssetSelected: boolean;
  isHousekeepingJob: boolean;
};

const validateJobLocations = ({
  values,
  isAssetSelected,
  isHousekeepingJob,
}: TValidateJobLocations) => {
  const selectedLocations =
    typeof values.location === 'object' &&
    !!values.location?.filter(
      (item) => !!item.id || (typeof item === 'string' && !!item),
    ).length;

  return (
    (!!selectedLocations && !isAssetSelected) ||
    (!!isHousekeepingJob && typeof values.location === 'string')
  );
};

const validateJobDepartment = (values: {
  department: {
    id: string;
  };
}) => {
  const selectedDepartment =
    typeof values.department === 'string'
      ? !!values.department
      : !!values.department?.id;
  return !!selectedDepartment;
};

type TCheckInProgressWithoutAssignee = {
  values: {
    status: TJobStatusesValues;
    assignee: string & {
      id?: string;
    };
  };
  JOB_STATUSES: TJobStatuses;
  JOB_ADVANCED_STATUSES: TJobAdvancedStatuses;
  AUTO_ASSIGNEE_ID?: string | number;
};

const checkInProgressWithoutAssignee = ({
  values,
  JOB_STATUSES,
  JOB_ADVANCED_STATUSES,
  AUTO_ASSIGNEE_ID,
}: TCheckInProgressWithoutAssignee) => {
  const isInProgressStatus = ![
    JOB_STATUSES.notStarted.value,
    JOB_STATUSES.onHold.value,
    JOB_ADVANCED_STATUSES.pending?.value,
  ].includes(values.status);

  const hasNoValidAssignee =
    !(
      typeof values?.assignee === 'string' ||
      typeof values?.assignee?.id === 'string'
    ) || String(values?.assignee?.id) === String(AUTO_ASSIGNEE_ID);

  return isInProgressStatus && hasNoValidAssignee;
};

type TValidateHousekeepingJob = {
  isHousekeepingJob: boolean;
  hasLocations: boolean;
  hasDepartment: boolean;
  mode?: TJobFormMode;
  hasChecklist: boolean;
  hasCurrentAssignee: boolean;
  hasPreviousAssignee: boolean;
};

export const validateHousekeepingJob = ({
  isHousekeepingJob,
  hasLocations,
  hasDepartment,
  mode,
  hasChecklist,
  hasCurrentAssignee,
  hasPreviousAssignee,
}: TValidateHousekeepingJob) => {
  if (!isHousekeepingJob) return false;

  if (!hasLocations || !hasDepartment) return false;

  if (
    mode === 'edit' &&
    hasChecklist &&
    hasPreviousAssignee &&
    !hasCurrentAssignee
  ) {
    return false;
  }

  return true;
};

type TValidateGuestAndInternalJob = {
  isHousekeepingJob: boolean;
  hasLocations: boolean;
  hasDepartment: boolean;
  hasAssets: boolean;
  hasItems: boolean;
  hasInProgressWithoutAssignee: boolean;
};

export const validateGuestAndInternalJob = ({
  isHousekeepingJob,
  hasItems,
  hasLocations,
  hasAssets,
  hasDepartment,
  hasInProgressWithoutAssignee,
}: TValidateGuestAndInternalJob) => {
  if (isHousekeepingJob) return false;

  return (
    hasItems &&
    (hasLocations || hasAssets) &&
    hasDepartment &&
    !hasInProgressWithoutAssignee
  );
};

type TValidateJobForm = {
  previousValues: any;
  mode?: TJobFormMode;
  FORM_OPTIONS: any;
  JOB_STATUSES: TJobStatuses;
  JOB_ADVANCED_STATUSES: TJobAdvancedStatuses;
  AUTO_ASSIGNEE_ID?: string | number;
  setSubmittable: (a: boolean) => void;
};

export const validateJobForm = ({
  previousValues,
  mode,
  FORM_OPTIONS,
  JOB_STATUSES,
  JOB_ADVANCED_STATUSES,
  AUTO_ASSIGNEE_ID,
  setSubmittable,
  // eslint-disable-next-line arrow-body-style
}: TValidateJobForm) => {
  return (values: any) => {
    const isHousekeepingJob = values.jobType === 'housekeeping';
    const isProjectJob = values.metadata?.source === JOB_METADATA.project;

    const hasChecklist = !!values?.checklist?.length;

    const { hasPreviousAssignee, hasCurrentAssignee } = getAssigneeValidation({
      values,
      previousValues,
    });

    const { hasItems, isAssetSelected } = validateJobItems({
      values,
      FORM_OPTIONS,
      mode,
      isProjectJob,
    });
    const hasAssets = validateJobAssets({ values, isAssetSelected });
    const hasLocations = validateJobLocations({
      values,
      isAssetSelected,
      isHousekeepingJob,
    });
    const hasDepartment = validateJobDepartment(values);

    const hasInProgressWithoutAssignee = checkInProgressWithoutAssignee({
      values,
      JOB_STATUSES,
      JOB_ADVANCED_STATUSES,
      AUTO_ASSIGNEE_ID,
    });

    const housekeepingJobFormValidationRules = validateHousekeepingJob({
      isHousekeepingJob,
      hasLocations,
      hasDepartment,
      mode,
      hasChecklist,
      hasPreviousAssignee,
      hasCurrentAssignee,
    });

    const guestAndInternalJobValidationRules = validateGuestAndInternalJob({
      isHousekeepingJob,
      hasItems,
      hasLocations,
      hasAssets,
      hasDepartment,
      hasInProgressWithoutAssignee,
    });

    setSubmittable(
      housekeepingJobFormValidationRules || guestAndInternalJobValidationRules,
    );
  };
};
