import React, { useState } from 'react';
import { Flex, Button, SPACING, COLORS, FONTS, Grid } from '@optii/ui-library';
import { FormOutlined } from '@ant-design/icons';
import { CreateChannel } from './CreateChannel';

export function SearchAndCreate() {
  const [open, setOpen] = useState(false);

  const { useBreakpoint } = Grid;

  const { xs } = useBreakpoint();

  const margin = xs
    ? SPACING.SIZE_MD
    : `0 ${SPACING.SIZE_MD}px ${SPACING.SIZE_MD}px 0`;

  return (
    <>
      <Flex
        gap={SPACING.SIZE_MD}
        style={{
          margin,
        }}
        justify="flex-end"
      >
        <Button
          onClick={() => setOpen((prev) => !prev)}
          type="text"
          icon={
            <FormOutlined
              style={{
                color: COLORS.secondary[5],
                fontSize: FONTS.h4.size,
              }}
            />
          }
        />
      </Flex>
      <CreateChannel onClose={() => setOpen(false)} open={open} />
    </>
  );
}
