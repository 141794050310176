import React, { useContext, useEffect, useState } from 'react';
import {
  DatePicker,
  Flex,
  FormInstance,
  FormItem,
  FormList,
  RadioGroup,
  Row,
  Col,
  useWatch,
  Button,
  ConfigProvider,
  InputNumber,
  Select,
  SPACING,
  Typography,
  FONTS,
} from '@optii/ui-library';
import { useTranslation } from 'react-i18next';

import { PERMISSIONS, SelectLocations } from '@optii/global';

import dayjs from 'dayjs';
import { useAccess, UserAccessContext } from '@optii/shared';
import { DeleteOutlined } from '@ant-design/icons';
import {
  ACTION_OPTIONS,
  PRIORITY_OPTIONS,
  PrioritySelectLabelRender,
  PrioritySelectOptionRender,
  STATUS_OPTIONS,
} from './constants';
import { FormContext, LocationWithAsset } from './context';
import Assignment from './Assignment';
import { NotesAndAttachments } from './NotesAndAttachments';
import { PredictiveDueTime } from './PredictiveDueTime';

type Props = {
  form: FormInstance;
};

const optionRender = ({ data }: { data: unknown }) => {
  const { label, assetTypeDisplayName, notes } = data as LocationWithAsset;
  return (
    <Flex vertical>
      <Typography.Text>{label}</Typography.Text>
      <Typography.Text>{assetTypeDisplayName}</Typography.Text>
      <Typography.Text
        style={{
          fontWeight: 400,
          fontSize: FONTS.small.size,
        }}
      >
        {notes}
      </Typography.Text>
    </Flex>
  );
};

export function Service({ form }: Props) {
  const { t } = useTranslation(['common', 'jobs']);
  const { user } = useContext(UserAccessContext.Context);
  const { can } = useAccess();
  const { shard, items, assets } = useContext(FormContext);

  const [itemOptions, setItemOptions] = useState(items.options);
  const [searchValue, setSearchValue] = useState<{
    [key: string]: string | undefined;
  }>({});

  useEffect(() => {
    setItemOptions(items.options);
  }, [items.options]);

  const jobItems = useWatch('items', form);

  const canCreateJobItem = can(PERMISSIONS.jobs.autocreate);

  const isAssetSelected = items.options.find(
    (item) => jobItems?.[0]?.name === item.value,
  )?.isAssociatedWithAnAsset;

  const assetOptions = assets.options.filter(
    (option) =>
      option.item ===
      items.options.find((jobItem) => jobItem.value === jobItems?.[0]?.name)
        ?.label,
  );

  return (
    <ConfigProvider
      theme={{
        components: {
          Radio: {
            borderRadius: SPACING.NONE,
          },
          Upload: {
            padding: SPACING.SIZE_MS,
          },
        },
      }}
    >
      <FormItem
        name="action"
        required
        rules={[
          {
            required: true,
            message: t('jobs:Please select an action.'),
          },
        ]}
      >
        <RadioGroup
          optionType="button"
          buttonStyle="solid"
          options={ACTION_OPTIONS}
          style={{
            flexWrap: 'wrap',
            whiteSpace: 'nowrap',
          }}
          block
        />
      </FormItem>
      <FormItem
        noStyle
        shouldUpdate={(prev, curr) => prev.property !== curr.property}
      >
        {({ getFieldValue }) =>
          getFieldValue('property') ? (
            <>
              <FormList name="items">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field) => (
                      <Row gutter={SPACING.SIZE_MS} key={field.key}>
                        {field.key > 0 ? (
                          <Col
                            style={{
                              textAlign: 'center',
                              height: 40,
                            }}
                          >
                            <Button
                              type="link"
                              onClick={() => remove(field.name)}
                              danger
                              style={{
                                marginTop: SPACING.SIZE_XL,
                              }}
                              icon={
                                <DeleteOutlined
                                  style={{
                                    fontSize: 20,
                                  }}
                                />
                              }
                            />
                          </Col>
                        ) : null}
                        <Col flex="auto">
                          <FormItem
                            name={[field.name, 'name']}
                            label={
                              !isAssetSelected
                                ? t('common:Item {{n}}', {
                                    n: field.name + 1,
                                  })
                                : t('common:Item')
                            }
                            required={field.key === 0}
                            rules={[
                              {
                                required: true,
                                message: t('jobs:Item is a required field'),
                              },
                            ]}
                            style={
                              !isAssetSelected
                                ? {
                                    marginBottom: SPACING.SIZE_SM,
                                  }
                                : undefined
                            }
                          >
                            <Select
                              placeholder={t('common:Select')}
                              style={{
                                width: '100%',
                              }}
                              onChange={() => {
                                form.setFieldValue('asset', undefined);
                              }}
                              searchValue={searchValue[field.key]}
                              onSearch={(value) =>
                                setSearchValue({ [field.key]: value })
                              }
                              handleOptionCreation={
                                canCreateJobItem
                                  ? (value) => {
                                      if (value) {
                                        const newJobItems = jobItems.map(
                                          (
                                            item: { name: string },
                                            index: number,
                                          ) => {
                                            if (index === field.key)
                                              return {
                                                name: value,
                                                amount: 1,
                                              };

                                            return item;
                                          },
                                        );

                                        form.setFieldValue(
                                          'items',
                                          newJobItems,
                                        );

                                        setItemOptions((prev) =>
                                          prev.concat([
                                            {
                                              label: value,
                                              value,
                                              isAssociatedWithAnAsset: false,
                                            },
                                          ]),
                                        );
                                      }
                                    }
                                  : undefined
                              }
                              allowClear
                              options={itemOptions.map((item) => {
                                if (
                                  jobItems.find(
                                    (jobItem: { name: string }) =>
                                      jobItem.name === item.label,
                                  )
                                ) {
                                  return {
                                    ...item,
                                    disabled: true,
                                  };
                                }
                                return item;
                              })}
                              optionFilterProp="label"
                              showSearch
                            />
                          </FormItem>
                        </Col>
                        {!isAssetSelected ? (
                          <Col flex="80px">
                            <FormItem
                              name={[field.name, 'amount']}
                              label={t('common:Amount')}
                              style={{
                                marginBottom: SPACING.SIZE_SM,
                              }}
                            >
                              <InputNumber
                                min={1}
                                max={100}
                                controls
                                style={{
                                  width: '100%',
                                }}
                              />
                            </FormItem>
                          </Col>
                        ) : null}
                      </Row>
                    ))}
                    {!isAssetSelected ? (
                      <Flex align="end" justify="flex-end">
                        <Button
                          onClick={() => add({ amount: 1 })}
                          style={{
                            marginTop: SPACING.SIZE_MD,
                          }}
                        >
                          {t('fields:Add Item')}
                        </Button>
                      </Flex>
                    ) : null}
                  </>
                )}
              </FormList>

              {isAssetSelected ? (
                <FormItem
                  name="asset"
                  label={t('fields:Location')}
                  rules={[
                    {
                      required: true,
                      message: t('jobs:This is a required field.'),
                    },
                  ]}
                >
                  <Select
                    placeholder={t('fields:Select...')}
                    optionFilterProp="label"
                    options={assetOptions}
                    optionRender={optionRender}
                  />
                </FormItem>
              ) : (
                <SelectLocations
                  form={form}
                  fieldName="locations"
                  propertyId={shard}
                  label={t('common:Location')}
                  isJob
                />
              )}

              <FormItem
                name="status"
                label={t('jobs:Status')}
                rules={[
                  {
                    required: true,
                    message: t('common:Status is a required field.'),
                  },
                ]}
                required
              >
                <Select
                  placeholder={t('common:Select...')}
                  options={STATUS_OPTIONS}
                />
              </FormItem>

              <FormItem
                name="priority"
                label={t('jobs:Priority Level')}
                rules={[
                  {
                    required: true,
                    message: t('jobs:Priority is a required field.'),
                  },
                ]}
                required
              >
                <Select
                  placeholder={t('common:Select...')}
                  options={PRIORITY_OPTIONS}
                  labelRender={PrioritySelectLabelRender}
                  optionRender={PrioritySelectOptionRender}
                />
              </FormItem>

              <FormItem name="doBy" label={t('jobs:Due By')}>
                <DatePicker
                  minDate={dayjs()}
                  locale={user?.preferredLang as any}
                  format="L LT"
                  needConfirm={false}
                  showTime={{
                    format: 'LT',
                  }}
                  style={{
                    width: '100%',
                  }}
                />
              </FormItem>

              <PredictiveDueTime form={form} />

              <Assignment form={form} />

              <NotesAndAttachments type="Service" form={form} />
            </>
          ) : null
        }
      </FormItem>
    </ConfigProvider>
  );
}
